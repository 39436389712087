
/* ********************************************************************************************************************************************************************************** */

/* laptops with hipdi */
@media (min-width: 1200px) and (max-width: 1499px) {
    .searchdiv,.searchdivf{
        width: 280px !important;
    }
    .ninecoldiv {
        padding: 0px 0px 0px 74px;
    }
    .howpay{
       /* width: 540px !important; */
      width: 436px !important;
    }
    .searchinput {
      width: 253px !important;
    }
    .start_dateeeeeee{
        width: 162px !important;
    }
    .pchat{
      height: 650px !important;
    }
    /* find_n_apply */
   /* ss*/
    .searchdivcontf{
      width: 330px !important;
    }
    .zipdivf{
      width: 200px !important;
    }
    .searchcontfind{
      margin-top: 20px !important;
    }
    .zipinputf{
       margin-right: 20px;
    }
    .searchdivcontf{
       margin-right: 30px !important;
    }
    /* client_dashboard */
    .selectall_checkbox{
      padding-left: 0px;
      padding-top: 16px !important;
    }
    .searchinputcont{
     width: 290px !important;
    }
    .zipdiv{
      width: 135px !important;
    }
    .rangediv{
      width: 214px !important;
    }
    .vabut{
       white-space: nowrap;
    }
    .clienttab{
      width: 64vw;
    }
    .clienttab ul{
      width: max-content;
   }
   .find_apply2{
       margin-top: 90px;
       min-width: 120px;
       width: 20% !important;
   }
    /* .nav-link{
      padding-left: 5px;
      padding-right: 5px;
   }*/
   .ee_buttcon{
      width: 136px !important;
   }
   .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }
}
/* ********************************************************************************************************************************************************************************** */

    /* laptops with touch & mdpi */
@media only screen and (min-width: 1030px)and (max-width: 1366px){
    .pchat{
      height: 545px !important;
    }
    .searchdiv,.searchdivf{
        width: 220px !important;
    }
    .zipdiv{
        width: 180px;
    }
    .ninecoldiv {
        padding: 0px 0px 0px 35px;
    }
    .tencoldiv span {
        padding: 0px 20px 0px 0px;
    }

    .threecoldiv h5{
        font-size: 19px;
    }
    .searchinput {
       width: 225px !important; 
       /* width: 148px !important;*/
    }
    .howpay{
        /* width: 459px !important; */
        width: 355px !important;
    }
    .start_dateeeeeee{
        /*width: 136px  !important;*/
       width: 126px  !important;
    }
    /* find_n_apply */
   /* ss*/
    .searchdivcontf{
      width: 300px !important;
    }
    .zipdivf{
    width: 170px !important;
    }
    /* client_dashboard */
    .selectall_checkbox {
      padding-left: 1px !important;
    }
    .searchinput{
      width: 280px !important;
    }
    /* find_Contractor */
    .searchdivclient {
      width: 270px !important;
    }
    .czipdivf {
      width: 148px !important;
    }
    .col-md-2 .selectall_checkbox .d-flex{
      display: none !important;
    }
    .searchinputcont{
      width: 173px !important;
    }
    .zipdiv {
      width: 135px !important;
    }
    .rangediv{
       width: 214px !important;
    }
   /* .ee_buttcon{
      width: 155px !important;
   }*/
    .wodpop {
    max-width: 1200px;
    width: 950px !important;
    }
}
/* ********************************************************************************************************************************************************************************** */

        /* sir laptop */
@media (min-width: 1500px) and (max-width: 1699px){
  .start_dateeeeeee{
      width: 180px !important;
  }
  .emailin {
    width: 487px !important;
  }
  /* find_n_apply */
 /* ss*/
 .col-md-9 {
      flex: 0 0 71%;
  }
  .searchcontfind{
    margin-top: 20px !important;
  }
  .zipinputf{
     margin-right: 20px;
  }
  .searchdivcontf{
     margin-right: 30px !important;
  }
    /* assigned_work */
  .assignworkninediv{
         padding-right: 70px !important;
  }
     /* client_dashboard */
  .selectall_checkbox{
       padding-left: 0px !important;
       padding-top: 16px !important;
  }
  .modal .modal-full-height .applicantappiliedwo{
      right: 0px !important;
  }
  .vabut{
  white-space: nowrap;
  }
  .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }
}
/* ********************************************************************************************************************************************************************************** */

            /* big desktops */
@media (min-width: 1900px){
  .start_dateeeeeee{
      width: 240px !important;
  }
  .checkboxselectcol{
    margin-top: auto;
  }
  .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }
}
/* ********************************************************************************************************************************************************************************** */

                /* big des */
@media (min-width: 1700px)and (max-width: 1899px){
    .ninecoldiv {
        padding: 0px 0px 0px 156px;
    }
    .searchdiv,.searchdivf{
        width: 510px !important;
    }
    .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }

}
/* ********************************************************************************************************************************************************************************** */

                    /* tab */
@media (min-width: 768px) and (max-width: 1023px) {

}
/* ********************************************************************************************************************************************************************************** */

/* Phone */
@media (max-width: 766px){
  /* contractor Dashboard */
  .contractordascontentmaindiv{
    padding-left: 164px !important;
  }
  .upperdivcdcont{
    display: flex !important;
  }
  .find_apply10{
    width: 80% !important;
  }
  .start_dateeeeeee{
      width: 162px !important;
  }
  .cnewworder_row{
    display: block !important;
  }
  /* dashboard */
  .side-nav.wide .collapsible a {
    padding-left: 11px !important;
    font-size: 11px !important;
  }
  .dashboardimg,.bancolimg,.awimg,.vwimg,.messageimg{
    width: 15px !important;
    margin: 0px 5px 0px 0px !important;
  }
  /* dashboard end */
  /*ss*/
  /* contractor_dashboard */
  .searchinputcont {
      width: 210px !important;
      margin-right: 30px !important;
  }
  .searchinputcont .input-group-prepend{
      display: inline-block !important;
  }
  .zipdiv {
      display: inline-block !important;
      width: 155px !important;
      padding-top: 2px !important;
      padding-bottom: 2px !important;
  }
  .ee_buttcon {
      display: inline-block !important;
      width: 119px !important;
  }
  .upperdivcdcont{
      margin-top: 61px !important;
      display: inline-block !important;
  }
  .inputfiledcont{
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  /* find_n_apply */
  .contractordascontentmaindiv{
  width: 1150px !important;
  }
  .searchcontfind{
    margin-top: 20px !important;
  }
  .zipinputf{
     margin-right: 20px;
  }
  .searchdivcontf{
     margin-right: 30px !important;
  }

  /* assigned_work */
  .assignworksearchddiv{
      width: 90px !important;
     margin-right: 15px;
  }
  .assignworkzipdfdiv{
    width: 72px !important;
  }
  .awoptiondiv select.browser-default.custom-select
  {
      width: 111px !important;
  }
  .assignworksearchbutfdiv {
    width: 80px !important;
  }
  .assignworkuppdiv{
     display: inline !important;
  }
  .assignworksearchbutfdiv{
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .assignworkninediv{
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .assignworkseainddiv{
    padding-left: 4px;
    padding-right: 4px;
  }
  .assignworkseaddiv{
    padding-right: 1px !important;
  }
  .assignworkmaindiv{
      padding-right: 4px !important;
  }
  .assignworkzipinddiv {
    margin-right: 10px !important;
  }
  /* work_order_mang */
  .wommaindiv{
    width: 697px !important;
    padding-left: 180px !important;
  }
  .womupperdiv {
  display: inline-block !important;
  }
  .searchinputwom {
  width: 200px !important;
  margin-right: 12px !important;
  }
  .cwowom_butt {
  width: 100px !important;
  padding: 0px !important;
  }
  .zipdivwom {
    width: 100px !important;
  }
  .zipinputwom{
  padding-right: 12px !important;
  }
  /* find_Contractor */
  .upperdivcdclient{
      display: inline-block !important;
  }
  .searchdivclient{
    width: 200px !important;
    margin-right: 10px !important;
  }
  .czipdivf{
    width: 111px !important;
  }
  .rangediv{
     width: 220px !important;
  }
  .rangewidth {
     width: 100% !important;
  }
  .searchclientfind{
    width: 75px !important;
  }
 .clientfindiv{
     padding-left: 170px !important;
     width: 630px !important;
  }
  .invitebutton{
     width:100px !important;
  }
  /* client_dashboard */
  .upperdivcdc{
    display: inline-block !important;
  }
  .searchinput{
       width: 180px !important;
  }
  .ee_butt{
     padding-left: 6px !important;
     padding-right: 6px !important;
  }
  .clientdascontentmaindiv {
      width: 993px !important;
      padding-left: 180px !important;
  }
  .selectall_checkbox{
      padding-top: 17px !important;
  }
  /* .cwo_butt .iwoebutt */
  .cwo_butt{
     padding-left: 6px !important;
     padding-right: 6px !important;
  }
  .iwoebutt{
     padding-left: 6px !important;
     padding-right: 6px !important;
  }
  /* user_mang */
  .navbartopnav{
      padding-left: 0px !important;
  }
  .usermagementdiv{
      width: 575px !important;
      padding-left: 200px !important;
  }
  .side-nav.wide{
    width: 10rem;
  }
  .clientcreateworkorderdiv{
      padding-left: 170px !important;
  }
  .register_div .modal-content {
    width: 395px !important;
  }
  .emailin {
      width: 333px !important;
  }
   /* create_work_order*/
  .browser-default .custom-select .per{
    width: 85px !important;
  }
  .per{
      width: 85px !important;
  }
  .wodpop {
    max-width: 1200px;
    width: 100% !important;
  }
  .workordermanagement_wodpop{
      width: 100% !important;
  }
  .selcdiv ,.typekeydiv , .selrdiv, .selwtdiv, .typezcdiv{
     display: inline-block !important;
     padding-left: 0px;
     padding-right: 0px;
     margin-bottom: 0px;
  }
  .inputfieldhome{
    display: inline-block !important;
  }
  .typekeyhome , .zipcodehome{
       width: inherit !important;
  }
  .most_visited_cat_head{
     margin-top: 200px !important;
  }
  .carousel-multi-item {
     margin-bottom: 3rem !important;
  }
  .typekeydiv input[type="text"] , .typezcdiv input[type="text"]{
    height: 39px !important;
  }
  .viewpop{
    margin-left: auto;
  }
  .register_div{
     width: 392px !important;
  }
  .emailin{
    width: 348px !important;
  }
  .navitems{
    width: 100vw !important;
  }
  .controls-top{
    top: 300px !important;
  }
  .mainhomediv{
    width: fit-content;
  }
  .navitem{
    width: 100% !important;
  }
  .navbarcol{
     background-color: #15bcd5;
  }
  .vabut{
     white-space: nowrap;
  }
  .locationtl{
     margin-right: 20px !important;
  }
  .foot_subtitleson{
     margin-right: 20px;
  }
  .contacttl{
     margin-right: 56px;
  }
  .foot_subtitlesup{
      margin-right: 75px;
  }
  .supporttl{
      margin-right: 73px;
  }
  .title_div p{
     text-align: justify;
  }
  .searchbtndiv{
      padding-top: 40px !important;
      padding-bottom: 50px !important;
  }
  .upperhomediv{
      padding-right: 15px;
  }
  .inputfieldhome{
    padding-left: 15px;
  }
  .text_div1{
     padding-right: 15px;
     padding-left: 15px;
  }
  .icongroupres{
     text-align-last: center;
  }
  .text_div3{
     padding-right: 15px;
     padding-left: 15px;
  }
  .signup_div{
    padding-top: 20px;
  }
  .iconsocial{
  text-align-last: center;
  }
    .usertable_row
  {
    margin-left: 0px !important;
  }
  .checkboxselectcol{
    display: inline-block;
    width: auto;
  }
  .clienttab ul{
      width: max-content;
  }
  .navlinkitem{
    padding-right: 6px;
    padding-left: 6px;
  }
  .timepicker{
   width: 76px;
  }  
  .react-datepicker-wrapper{
     display: block;
  }
  .checkboxselectcol{
    padding-right: 20px !important;
  }
  

}
/* ********************************************************************************************************************************************************************************** */

/* Ipad pro */
@media (min-width: 992px) and (max-width: 1199px) {
  .start_dateeeeeee{
      width: 115px  !important;
  }
  .pchat{
      height: 1288px !important;
  }
  /* contractor_dashboard */
  /* ss*/
  .searchinputcont{
    width: 175px !important;
  }
  .zipdiv{
    width: 130px !important;
  }
  /* find_n_apply */
  .searchdivcontf {
    width: 280px !important;
  }
  .zipdivf {
    width: 160px !important;
  }
  .contractordascontentmaindiv{
    width: 1150px !important;
  }
  .searchcontfind{
     margin-top: 20px !important;
  }
  .zipinputf{
      margin-right: 20px;
  }
  .searchdivcontf{
      margin-right: 30px !important;
  }

  /* assigned_work */
  .assignworksearchddiv{
      width: 300px !important;
  }
  .assignworkzipdfdiv{
     width: 130px !important;
  }
  .awoptiondiv select.browser-default.custom-select
  {
     width: 112px !important;
  }
   /* work_order_mang */
  .cwowom_butt {
      width: 107px;
  }
  .searchinputwom {
    width: 240px !important;
    margin-right: 30px !important;
  }
  .zipdivwom{
    width: 140px !important;
  }
   /* user_mang */
  .form-check{
    padding-left: 10px !important;
  }
   /* find_Contractor */
  .searchdivclient {
      width: 175px !important;
  }
  .czipdivf{
    width: 95px !important;
  }
  .searchclientfind{
    margin-left: 15px !important;
    width: 75px !important;
  }
  .upperdivcdclient{
    display: inline-block !important;
  }
   /* client_dashboard */
  .selectall_checkbox {
    padding-left: 42px !important;
  }
  .selectall_checkbox {
    padding-left: 0px !important;
  }
  .clienttab .nav-link {
    padding: .5rem 0px !important;
    margin-right: 8px !important;
  }
  .rangediv {
      width: 340px !important;
  }
  .searchinput{
     width: 340px !important;
  }
  .vabut{
    height: 36px !important;
  }
  .searchbardivcdc{
      display: inline-block !important;
  }
  .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }
  .workordermanagement_wodpop{
     width: 100% !important;
  }
  .applicantappiliedwo{
    right: 0px !important;
    width: 400px !important; 
  }
  .vabut{
    white-space: nowrap;
  }
  .clienttab{
     width: max-content;
  }
   .ee_buttcon{
      width: 110px !important;
      white-space: nowrap;
  }
  .checkboxselectcol{
    padding-right: 20px !important;
  }

}
/* ********************************************************************************************************************************************************************************** */

    /* Ipad */
@media (min-width: 767px) and (max-width: 991px){
  .start_dateeeeeee{
      width: 115px  !important;
  }
  .pchat{
    height: 750px !important;
  }
  .copyicon{
    /* margin-right: 12px !important; */
  }
   /* contractor_dashboard */
   /* ss*/
  .searchinputcont{
    width: 200px !important;
    margin-right: 20px !important;
  }
  .ee_buttcon {
    width: 113px !important
  }
  .zipinputcont{
    padding-right: 20px;
  }
  /* find_n_apply */
  .searchdivcontf {
    width: 280px !important;
  }
  .zipdivf {
    width: 160px !important;
  }
  .contractordascontentmaindiv{
    width: 1150px !important;
  }
  .searchcontfind{
    margin-top: 20px !important;
  }
  .zipinputf{
    margin-right: 20px;
  }
  .searchdivcontf{
    margin-right: 30px !important;
  }
   /* assigned_work */
  .assignworksearchddiv{
    width: 205px !important;
  }
  .assignworkzipdfdiv{
    width: 100px !important;
  }
  .awoptiondiv select.browser-default.custom-select
  {
    width: 112px !important;
  }
  .assignworksearchbutfdiv {
    width: 75px !important;
  }
  /* work_order_mang */
  .searchinputwom {
    width: 134px !important;
    margin-right:6px !important;
  }
  .zipdivwom {
    width: 110px !important;
  }
  .cwowom_butt {
      width: 100px;
  }
  .zipinputwom{
    padding-right: 5px !important;
  }
  /* find_Contractor */
  .searchdivclient {
      width: 145px !important;
  }
  .czipdivf{
    width: 95px !important;
  }
  .searchclientfind{
    margin-left: 8px !important;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    width: 65px !important;
  }
  .ninecoldivclientfind{
     padding-left: 0px !important;
  }
  .searchdivclient{
     margin-right: 10px !important;
  }
  .clientfindiv{
     width: 861px !important;
  }
  .rangewidth{
     width: 70px !important;
  }
  .rangediv{
      padding-right: 0px !important;
      width: 300px !important;
  }
  .upperdivcdclient{
     display: inline-block !important;
  }
   /* client_dashboard */
  .searchinput{
     width: 340px !important;
  }
  .cke_editable .cke_editable_themed .cke_contents_ltr .cke_show_borders{
     background-color:#343a40 !important;
  }
  .cke_editable {
     background-color:#343a40 !important;
  }
  .applicantappiliedwo{
      position: absolute;
      display: flex !important;
  }
  .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }
  .clienttab .nav-link {
    padding: .5rem 0px !important;
    margin-right: 8px !important;
    font-size: 12.8px;
  }
  .vabut{
    height: 36px !important;
    white-space: nowrap;
  }
  .searchbardivcdc{
      display: inline-block !important;
  }
  .perhour_device{
     margin-left: 27px;
  }
  .titlee_div{
     margin-top: 15px;
  }
  .enddate_div{
      margin-top: 32px;
  }
  .perdevice_hour{
     margin-left: 44px;
  }
  .work_div{
     margin-left: 73px;
  }
  .delive_div{
     margin-left: 27px;
  }
  .inputfieldhome input {
    width: 110px !important;
  }
  .navitems{
    padding-right: 2% !important;
  }
  .ninecoldivclientfind{
    margin-left: 15px;
  }
  .clienttab ul{
     width: max-content;
  }
  .checkboxselectcol{
    padding-left: 6px;
  }
  .selcdiv select, .selrdiv select, .selwtdiv select{
    width: 138px !important;
  }
  
}
/* ********************************************************************************************************************************************************************************** */
/* Mac */
@media (min-width: 1600px) and (max-width: 1600px){
  .start_dateeeeeee {
      width: 189px !important;
  }
  .pchat{
    height: 144vw !important;
  }
  .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }
}
