@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family: Poppins, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#16222d !important;
  overflow: auto !important;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

.auth-text {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}
.ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}
.ql-editor {
  color: black;
  min-height: 18em;
}
.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.wo-status-on-hold{
  border-radius: 4px;
  color: #fff;
  background-color: red;
  white-space: nowrap;
  padding: 3px 5px;
}
.text-elipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.prefered-icon {
  display: flex;
  padding: 0px 10px;
  width: fit-content;
  font-size: 20px;
  cursor: pointer;
  border: 2px solid white;
  align-self: self-start;
}
.modal-overflow {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.filters-button{
  margin: 0 !important;
  padding: .375rem .75rem !important;
  width: 100% !important;
  color: #fff !important;
  box-shadow: none !important;
  font-size: 1rem !important;
}

.no-margins{
  margin: 0 !important;
}

.no-paddings{
  padding: 0 !important;
}

.flex-box{
  display: flex !important;
}

.flex-align-center{
  align-items: center !important;

}

.input-field{
  border: none;
  padding: 0.25rem 0.7rem;
}

.width-inherit{
  width: inherit;
}

.h-paddings{
  padding: 1rem 0;
}

.h-paddings-half{
  padding: 0.5rem 0;
}

.v-paddings{
  padding: 0 1rem;
}

.v-paddings-half{
  padding: 0 0.5rem;
}

.modal-backdrop{
  background-color: #212529cf !important;
}

.custom-style-multiple-select-dropdown{
  background-color: #fff;
}

.custom-style-multiple-select-dropdown input{
  padding-left: 0.5rem !important;
  box-sizing: border-box !important;
}

.custom-style-multiple-select-dropdown ul{
  top: 0 !important;
}

.custom-style-multiple-select-dropdown div.md-form{
  margin: 0.5rem 0 !important;
}

.custom-style-multiple-select-dropdown label.mdb-main-label.active{
  display: none;
}

.react-datepicker-popper{
  z-index: 9 !important;
}

.color-white{
  color: white !important;
}

.form-check-input[type="checkbox"].filled-in:checked + label::after, label.btn input[type="checkbox"].filled-in:checked + label::after{
  border-color: white !important;
  background-color: transparent !important;
}
 .form-check-input[type="checkbox"].filled-in:not(:checked)+label:after, label.btn input[type="checkbox"].filled-in:not(:checked)+label:after {
   border-color: white !important;
 }

.regcheck.form-check-input[type="checkbox"].filled-in:not(:checked)+label:after, label.btn input[type="checkbox"].filled-in:not(:checked)+label:after {
  border-color: black !important;
}

.regcheck.form-check-input[type="checkbox"].filled-in:checked + label::after, label.btn input[type="checkbox"].filled-in:checked + label::after{
  border-color: black !important;
  background-color: transparent !important;
}

.regcheck.form-check-input[type="checkbox"].filled-in:checked + label::before, .checkboxdivres label.btn input[type="checkbox"].filled-in:checked + label::before {
  border-bottom-color: black;
  border-right-color: black;
}

.input-field:disabled {
  background-color: grey !important;
}
.form-check-input[type="checkbox"].filled-in:not(:checked)+label:before, label.btn input[type="checkbox"].filled-in:not(:checked)+label:before {
   border: none !important;
}
.loader-element{
  background-color: transparent !important;
  box-shadow: none !important;
  align-items: center;
}

.payment-data-tables .page-link, .payment-data-tables select{
  background-color: white !important;
  color: black !important;
}

.payment-data-tables .disabled .page-link{
  background-color: grey !important;
}

.modal-width-500{
  width: 500px !important;
}

.card-date-input{
  background-color: white;
  color: black;
  align-items: center;
}

.card-payment-method{
  color: white !important;
  background-color: #182837 !important;
}

.modal-content-background{
  background-color: #1e2f41 !important;
}

.work-order-message-system-modal{
  max-width: 700px !important;
  width: 35rem !important;
}

.background-green, .btn-default.background-green {
    background-color: #6cff2e !important;
}

.message-sticker{
  border-radius: 4px;
  background-color: white;
  color: black;
  padding: 0.5rem;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
  text-align: left;
}

.radio-input-label{
  font-size: 0.85rem;
  white-space: nowrap;
  margin: 0 0.5rem;
}

.portal-element{
  position: absolute;
  z-index: 99999;
}

.background-dark-blue{
  background-color: #16222d;
}

.date-time-input-group{
  max-width: 250px;
}

.date-time-input-group .form-control{
  background-color: #1e2f41;
  border: none;
  border-radius: 0;
  color: white;
}

.text-in-one-line{
  white-space: nowrap !important;
}

.tools-element{
  margin: 0px 10px 5px 0px;
  background-color: #3e566a;
  border-radius: 4px;
  border: 1px solid #78a1d0;
  padding: 0.2rem 0.5rem;
}

.card-label-container{
  height: 2.1rem;
  position: relative;
  justify-content: center;
}

.card-label-container .card-label{
  position: absolute;
}

.card-label{
  border-radius: 4px;
  white-space: nowrap;
  padding: 0.2rem 1rem;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  z-index: 9;
}

.card-label span{
  margin-left: 0.5rem;
}

.yellow-card-label{
  background-color: #ffff32;
  color: #333333;
}

.last-check-in-out-info-container{
  border: 1px solid white;
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  width: max-content;
}

.last-check-in-out-info-container label{
  margin-bottom: 0.2rem;
  white-space: nowrap;
  line-height: 1;
  font-weight: bold;
}

.modal-width-to-max-content{
  max-width: max-content !important;
}

.modal-container-width-to-max-content{
  width: 650px !important;
  padding: 0.5rem;
}

input[type="checkbox"].form-control:disabled, input[type="checkbox"].form-control[readonly] .task-required {
  opacity: 0!important;
}

.ul-info-toast{
  overflow: hidden  auto;
  max-height: 5rem;
}

.adjustment-label{
  min-height: 2rem;
  white-space: pre-wrap;
  padding: 0.5rem;
  display: block;
}

.minimal-style{
  font-size: 0.685rem;
  font-weight: bold;
  margin: 0px 10px 5px 0px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid white;
  padding: 0.2rem 0.5rem;
}

.white-tooltip-title .tooltip {
  color: black;
  background-color: white;
}

.white-tooltip-title .tooltip[x-placement^="right"] .popover_arrow::before{
  border-color: transparent white transparent transparent !important;
}
.white-tooltip-title .tooltip[x-placement^="top"] .popover_arrow::before{
  border-color: white transparent transparent transparent !important;
}
.white-tooltip-title .tooltip[x-placement^="left"] .popover_arrow::before{
  border-color: transparent transparent transparent white !important;
}
.white-tooltip-title .tooltip[x-placement^="bottom"] .popover_arrow::before{
  border-color: transparent transparent white transparent !important;
}

.disabled-standard-button-style{
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background: inherit;
  color: inherit;
}

.disabled-standard-button-style:focus{
  border: dashed 1px;
}

.tooltip-on-right-side .tooltip{
  left: 50% !important;
}

.padding-05-02-rem{
  padding: 0.7rem 0.3rem !important;
}

.checkmark-color{
  position: relative;
  color: #ff7043 !important;
  display: inline-block;
  height: 1.5625rem;
  padding-left: 35px;
  line-height: 1.5625rem;
}

.checkmark-color::before{
  position: absolute;
  content: '';
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-right: 2px solid #ff7043;
  border-bottom: 2px solid #ff7043;
  border-left: 2px solid transparent;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
  margin-top: 3px;
  border-radius: 1px;
}

.checkmark-color::after{
  content: '';
  border: 2px solid #ff7043;
  position: absolute;
  left: 0;
  background-color: transparent !important;
  width: 20px;
  height: 20px;
  border-radius: .125rem;
  box-sizing: border-box;
}

.tr-td-font-bold td{
  font-weight: bold;
}
.Toastify__toast-container{
  z-index: 999999!important;
}
.Toastify__toast .Toastify__toast--error {
  z-index: 999999 !important;
}
.tr-td-padding td{
  padding: 0.1rem 0.1rem;
}

.tr-td-font-size-08 td{
  font-size: 0.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle !important;
}
.cke_dialog_container{
  z-index:100101!important;
}

.confirm{
  width: fit-content;
  background: #27a745;
  padding: 0 5px;
}
.need-confirm{
  width: fit-content;
  padding: 0 5px;
}
.less-twelve{
  background: #aa66cc;
}
.more-twelve{
  background: #ffff32;
  color: red;
}

.border-bottom-line{
  border-bottom: 1px solid #1e2f41;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.margin-bottom-10{
  margin-bottom: 1rem;
}

.font-size-08{
  font-size: 0.8rem;
}

.padding-bottom-05{
  padding-bottom: 0.5rem;
}

.padding-bottom-10 {
  padding-bottom: 1rem;
}

.padding-left-12-5{
  padding-left: 1.25rem;
}
.form-apply-modal-basic-type{
  width: 24%;
}

.padding-right-10{
  padding-right: 1rem;
}

.form-control.custom-input-style{
  background-color: #1e2f41;
  border: none;
  border-radius: 0;
  color: white;
}

.font-size-08 .form-control{
  font-size: 0.8rem;
}

.span-error{
  color: red;
  font-weight: bold;
  font-size: 0.8rem;
}

.error-text{
  color: red;
  font-weight: bold;
}

.drag-and-drop-element{
  background-color: white;
  width: 36rem;
  height: 5rem;
  border-radius: 4px;
  position: relative;
  padding: 5px;
}

.drag-and-drop-element input {
  opacity: 0;
  position: absolute;
  z-index: 1;
  color: gray;
}
.drag-and-drop-element:after {
  content: "Drag and drop your file here or click here to add file";
  padding: 7px;
  position: absolute;
  color: black;
  bottom: 0;
}

.position-absolute-top-left-corner{
  position: absolute;
  top: 0;
  left: 0;
}

.checkbox-select-list{
  position: absolute;
  background-color: white;
  color: black;
  z-index: 99;
  padding: 0 10px 10px 0;
  white-space: nowrap;
  border-radius: 4px;
  top: 150%;
}

.hide-element{
  display: none !important;
}

.text-ellipsis{
  width: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle !important;
}

.border-bottom-line-red{
  font-weight: bold;
  border-bottom: 1px solid red;
  padding-bottom: 5px;
}

.max-width-half{
  max-width: 50%;
}

.min-width-half{
  min-width: 50%;
}

.other-value-input-right{
  padding: 0.2rem 0.5rem !important;
  height: calc(0.8em + 0.8rem + 2px) !important;
  margin-left: 0.5rem;
}

.modal-width-900{
  width: 900px !important;
}

.side-nav-link-with-icon span{
  display: flex;
  align-items: center;
}

.padding-02-05{
  padding: 0.2rem 0.5rem !important;
}

.border-bottom-line-white-bold{
  border-bottom: 2px solid #ffffff;
  padding-bottom: 0.2rem;
  margin-bottom: 0.2rem;
}

.border-top-line-white-bold{
  border-top: 2px solid #ffffff;
  padding-top: 0.2rem;
  margin-top: 0.2rem;
}

.select-container{
  width: 400px;
  margin: 1rem;
}

.font-size-10{
  font-size: 1rem !important;
}

.margin-top-20{
  margin-top: 2rem;
}

.icon-label{
  position: absolute;
  font-size: 0.7rem;
  font-weight: bold;
  z-index: 2;
  top: 100%;
  left: 25%;
}

.text-align-right{
  text-align: right;
}

.text-align-center{
  text-align: center;
}

.text-align-left{
  text-align: left;
}

.dimension-to-white-input{
  background-color: white;
  color: black;
  padding-right: 0.5rem;
}

.no-border-radius{
  border-radius: 0 !important;
}

.width-100-percent{
  width: 100%;
}
