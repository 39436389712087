@import url(https://fonts.googleapis.com/css?family=Poppins&display=swap);
body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family: Poppins, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color:#16222d !important;
  overflow: auto !important;
}

code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
}

.auth-text {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ql-container {
  border-bottom-left-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  background: #fefcfc;
}
.ql-bubble .ql-editor {
  border: 1px solid #ccc;
  border-radius: 0.5em;
}
.ql-editor {
  color: black;
  min-height: 18em;
}
.ql-snow.ql-toolbar {
  display: block;
  background: #eaecec;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
}

.wo-status-on-hold{
  border-radius: 4px;
  color: #fff;
  background-color: red;
  white-space: nowrap;
  padding: 3px 5px;
}
.text-elipses {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.prefered-icon {
  display: flex;
  padding: 0px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  font-size: 20px;
  cursor: pointer;
  border: 2px solid white;
  align-self: self-start;
}
.modal-overflow {
  overflow-x: hidden !important;
  overflow-y: auto !important;
}

.filters-button{
  margin: 0 !important;
  padding: .375rem .75rem !important;
  width: 100% !important;
  color: #fff !important;
  box-shadow: none !important;
  font-size: 1rem !important;
}

.no-margins{
  margin: 0 !important;
}

.no-paddings{
  padding: 0 !important;
}

.flex-box{
  display: flex !important;
}

.flex-align-center{
  align-items: center !important;

}

.input-field{
  border: none;
  padding: 0.25rem 0.7rem;
}

.width-inherit{
  width: inherit;
}

.h-paddings{
  padding: 1rem 0;
}

.h-paddings-half{
  padding: 0.5rem 0;
}

.v-paddings{
  padding: 0 1rem;
}

.v-paddings-half{
  padding: 0 0.5rem;
}

.modal-backdrop{
  background-color: #212529cf !important;
}

.custom-style-multiple-select-dropdown{
  background-color: #fff;
}

.custom-style-multiple-select-dropdown input{
  padding-left: 0.5rem !important;
  box-sizing: border-box !important;
}

.custom-style-multiple-select-dropdown ul{
  top: 0 !important;
}

.custom-style-multiple-select-dropdown div.md-form{
  margin: 0.5rem 0 !important;
}

.custom-style-multiple-select-dropdown label.mdb-main-label.active{
  display: none;
}

.react-datepicker-popper{
  z-index: 9 !important;
}

.color-white{
  color: white !important;
}

.form-check-input[type="checkbox"].filled-in:checked + label::after, label.btn input[type="checkbox"].filled-in:checked + label::after{
  border-color: white !important;
  background-color: transparent !important;
}
 .form-check-input[type="checkbox"].filled-in:not(:checked)+label:after, label.btn input[type="checkbox"].filled-in:not(:checked)+label:after {
   border-color: white !important;
 }

.regcheck.form-check-input[type="checkbox"].filled-in:not(:checked)+label:after, label.btn input[type="checkbox"].filled-in:not(:checked)+label:after {
  border-color: black !important;
}

.regcheck.form-check-input[type="checkbox"].filled-in:checked + label::after, label.btn input[type="checkbox"].filled-in:checked + label::after{
  border-color: black !important;
  background-color: transparent !important;
}

.regcheck.form-check-input[type="checkbox"].filled-in:checked + label::before, .checkboxdivres label.btn input[type="checkbox"].filled-in:checked + label::before {
  border-bottom-color: black;
  border-right-color: black;
}

.input-field:disabled {
  background-color: grey !important;
}
.form-check-input[type="checkbox"].filled-in:not(:checked)+label:before, label.btn input[type="checkbox"].filled-in:not(:checked)+label:before {
   border: none !important;
}
.loader-element{
  background-color: transparent !important;
  box-shadow: none !important;
  align-items: center;
}

.payment-data-tables .page-link, .payment-data-tables select{
  background-color: white !important;
  color: black !important;
}

.payment-data-tables .disabled .page-link{
  background-color: grey !important;
}

.modal-width-500{
  width: 500px !important;
}

.card-date-input{
  background-color: white;
  color: black;
  align-items: center;
}

.card-payment-method{
  color: white !important;
  background-color: #182837 !important;
}

.modal-content-background{
  background-color: #1e2f41 !important;
}

.work-order-message-system-modal{
  max-width: 700px !important;
  width: 35rem !important;
}

.background-green, .btn-default.background-green {
    background-color: #6cff2e !important;
}

.message-sticker{
  border-radius: 4px;
  background-color: white;
  color: black;
  padding: 0.5rem;
  font-size: 0.85rem;
  margin-bottom: 0.5rem;
  text-align: left;
}

.radio-input-label{
  font-size: 0.85rem;
  white-space: nowrap;
  margin: 0 0.5rem;
}

.portal-element{
  position: absolute;
  z-index: 99999;
}

.background-dark-blue{
  background-color: #16222d;
}

.date-time-input-group{
  max-width: 250px;
}

.date-time-input-group .form-control{
  background-color: #1e2f41;
  border: none;
  border-radius: 0;
  color: white;
}

.text-in-one-line{
  white-space: nowrap !important;
}

.tools-element{
  margin: 0px 10px 5px 0px;
  background-color: #3e566a;
  border-radius: 4px;
  border: 1px solid #78a1d0;
  padding: 0.2rem 0.5rem;
}

.card-label-container{
  height: 2.1rem;
  position: relative;
  justify-content: center;
}

.card-label-container .card-label{
  position: absolute;
}

.card-label{
  border-radius: 4px;
  white-space: nowrap;
  padding: 0.2rem 1rem;
  font-size: 0.8rem;
  margin-top: 0.5rem;
  z-index: 9;
}

.card-label span{
  margin-left: 0.5rem;
}

.yellow-card-label{
  background-color: #ffff32;
  color: #333333;
}

.last-check-in-out-info-container{
  border: 1px solid white;
  color: white;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  width: -webkit-max-content;
  width: max-content;
}

.last-check-in-out-info-container label{
  margin-bottom: 0.2rem;
  white-space: nowrap;
  line-height: 1;
  font-weight: bold;
}

.modal-width-to-max-content{
  max-width: -webkit-max-content !important;
  max-width: max-content !important;
}

.modal-container-width-to-max-content{
  width: 650px !important;
  padding: 0.5rem;
}

input[type="checkbox"].form-control:disabled, input[type="checkbox"].form-control[readonly] .task-required {
  opacity: 0!important;
}

.ul-info-toast{
  overflow: hidden  auto;
  max-height: 5rem;
}

.adjustment-label{
  min-height: 2rem;
  white-space: pre-wrap;
  padding: 0.5rem;
  display: block;
}

.minimal-style{
  font-size: 0.685rem;
  font-weight: bold;
  margin: 0px 10px 5px 0px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid white;
  padding: 0.2rem 0.5rem;
}

.white-tooltip-title .tooltip {
  color: black;
  background-color: white;
}

.white-tooltip-title .tooltip[x-placement^="right"] .popover_arrow::before{
  border-color: transparent white transparent transparent !important;
}
.white-tooltip-title .tooltip[x-placement^="top"] .popover_arrow::before{
  border-color: white transparent transparent transparent !important;
}
.white-tooltip-title .tooltip[x-placement^="left"] .popover_arrow::before{
  border-color: transparent transparent transparent white !important;
}
.white-tooltip-title .tooltip[x-placement^="bottom"] .popover_arrow::before{
  border-color: transparent transparent white transparent !important;
}

.disabled-standard-button-style{
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background: inherit;
  color: inherit;
}

.disabled-standard-button-style:focus{
  border: dashed 1px;
}

.tooltip-on-right-side .tooltip{
  left: 50% !important;
}

.padding-05-02-rem{
  padding: 0.7rem 0.3rem !important;
}

.checkmark-color{
  position: relative;
  color: #ff7043 !important;
  display: inline-block;
  height: 1.5625rem;
  padding-left: 35px;
  line-height: 1.5625rem;
}

.checkmark-color::before{
  position: absolute;
  content: '';
  top: 0;
  left: 1px;
  width: 8px;
  height: 13px;
  border-top: 2px solid transparent;
  border-right: 2px solid #ff7043;
  border-bottom: 2px solid #ff7043;
  border-left: 2px solid transparent;
  transform: rotateZ(37deg);
  transform-origin: 100% 100%;
  margin-top: 3px;
  border-radius: 1px;
}

.checkmark-color::after{
  content: '';
  border: 2px solid #ff7043;
  position: absolute;
  left: 0;
  background-color: transparent !important;
  width: 20px;
  height: 20px;
  border-radius: .125rem;
  box-sizing: border-box;
}

.tr-td-font-bold td{
  font-weight: bold;
}
.Toastify__toast-container{
  z-index: 999999!important;
}
.Toastify__toast .Toastify__toast--error {
  z-index: 999999 !important;
}
.tr-td-padding td{
  padding: 0.1rem 0.1rem;
}

.tr-td-font-size-08 td{
  font-size: 0.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle !important;
}
.cke_dialog_container{
  z-index:100101!important;
}

.confirm{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background: #27a745;
  padding: 0 5px;
}
.need-confirm{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0 5px;
}
.less-twelve{
  background: #aa66cc;
}
.more-twelve{
  background: #ffff32;
  color: red;
}

.border-bottom-line{
  border-bottom: 1px solid #1e2f41;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
}

.margin-bottom-10{
  margin-bottom: 1rem;
}

.font-size-08{
  font-size: 0.8rem;
}

.padding-bottom-05{
  padding-bottom: 0.5rem;
}

.padding-bottom-10 {
  padding-bottom: 1rem;
}

.padding-left-12-5{
  padding-left: 1.25rem;
}
.form-apply-modal-basic-type{
  width: 24%;
}

.padding-right-10{
  padding-right: 1rem;
}

.form-control.custom-input-style{
  background-color: #1e2f41;
  border: none;
  border-radius: 0;
  color: white;
}

.font-size-08 .form-control{
  font-size: 0.8rem;
}

.span-error{
  color: red;
  font-weight: bold;
  font-size: 0.8rem;
}

.error-text{
  color: red;
  font-weight: bold;
}

.drag-and-drop-element{
  background-color: white;
  width: 36rem;
  height: 5rem;
  border-radius: 4px;
  position: relative;
  padding: 5px;
}

.drag-and-drop-element input {
  opacity: 0;
  position: absolute;
  z-index: 1;
  color: gray;
}
.drag-and-drop-element:after {
  content: "Drag and drop your file here or click here to add file";
  padding: 7px;
  position: absolute;
  color: black;
  bottom: 0;
}

.position-absolute-top-left-corner{
  position: absolute;
  top: 0;
  left: 0;
}

.checkbox-select-list{
  position: absolute;
  background-color: white;
  color: black;
  z-index: 99;
  padding: 0 10px 10px 0;
  white-space: nowrap;
  border-radius: 4px;
  top: 150%;
}

.hide-element{
  display: none !important;
}

.text-ellipsis{
  width: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle !important;
}

.border-bottom-line-red{
  font-weight: bold;
  border-bottom: 1px solid red;
  padding-bottom: 5px;
}

.max-width-half{
  max-width: 50%;
}

.min-width-half{
  min-width: 50%;
}

.other-value-input-right{
  padding: 0.2rem 0.5rem !important;
  height: calc(0.8em + 0.8rem + 2px) !important;
  margin-left: 0.5rem;
}

.modal-width-900{
  width: 900px !important;
}

.side-nav-link-with-icon span{
  display: flex;
  align-items: center;
}

.padding-02-05{
  padding: 0.2rem 0.5rem !important;
}

.border-bottom-line-white-bold{
  border-bottom: 2px solid #ffffff;
  padding-bottom: 0.2rem;
  margin-bottom: 0.2rem;
}

.border-top-line-white-bold{
  border-top: 2px solid #ffffff;
  padding-top: 0.2rem;
  margin-top: 0.2rem;
}

.select-container{
  width: 400px;
  margin: 1rem;
}

.font-size-10{
  font-size: 1rem !important;
}

.margin-top-20{
  margin-top: 2rem;
}

.icon-label{
  position: absolute;
  font-size: 0.7rem;
  font-weight: bold;
  z-index: 2;
  top: 100%;
  left: 25%;
}

.text-align-right{
  text-align: right;
}

.text-align-center{
  text-align: center;
}

.text-align-left{
  text-align: left;
}

.dimension-to-white-input{
  background-color: white;
  color: black;
  padding-right: 0.5rem;
}

.no-border-radius{
  border-radius: 0 !important;
}

.width-100-percent{
  width: 100%;
}

/* / laptops with hipdi / */
@media (min-width: 1200px) and (max-width: 1499px) {
  /* find_n_apply */
 /* .searchdivcontf{
    width: 330px !important;
  }
  .zipdivf{
    width: 200px !important;
  }
  .searchcontfind{
    margin-top: 20px !important;
  }
  .zipinputf{
     margin-right: 20px;
  }
  .searchdivcontf{
     margin-right: 30px !important;
  }*/
  /* client_dashboard */
  /*  .selectall_checkbox{
    padding-left: 0px;
    padding-top: 16px !important;
   }
   .searchinputcont{
   width: 290px !important;
    }
  .zipdiv {
    width: 135px !important;
  }
  .rangediv{
        width: 214px !important;
  }
  .vabut{
     white-space: nowrap;
   }*/
}
/* / laptops with touch & mdpi / */
@media only screen and (min-width: 1030px)and (max-width: 1366px){
  /* find_n_apply */
   /* .searchdivcontf{
      width: 300px !important;
    }
    .zipdivf{
    width: 170px !important;
    }*/
    /* client_dashboard */
    /*.selectall_checkbox {
      padding-left: 1px !important;
    }
    .searchinput{
      width: 280px !important;
    }*/
    /* find_Contractor */
    /*.searchdivclient {
      width: 270px !important;
    }
    .czipdivf {
      width: 148px !important;
    }
    .col-md-2 .selectall_checkbox .d-flex{
      display: none !important;

    }
    .searchinputcont{
      width: 173px !important;
    }
    .zipdiv {
      width: 135px !important;
    }
    .rangediv{
       width: 214px !important;
    }*/
}
/* / sir laptop / */
@media (min-width: 1500px) and (max-width: 1699px){
   /* find_n_apply */
   /*.col-md-9 {
        flex: 0 0 71%;
    }
    .searchcontfind{
      margin-top: 20px !important;
    }
    .zipinputf{
       margin-right: 20px;
    }
    .searchdivcontf{
       margin-right: 30px !important;
    }
   */
    /* assigned_work */
   /*.assignworkninediv{
         padding-right: 70px !important;
    }*/
     /* client_dashboard */
    /*.selectall_checkbox{
       padding-left: 0px !important;
       padding-top: 16px !important;
    }
    .modal .modal-full-height .applicantappiliedwo{
      right: 0px !important;
      }*/
 }
/* / big desktops / */
@media (min-width: 1900px){

}
/* / big des / */
@media (min-width: 1700px)and (max-width: 1899px){

}
/* / tab / */
@media (min-width: 768px) and (max-width: 1023px) {

}
/* / Phone / */
@media (max-width: 766px){

  /* contractor_dashboard */
  /* .searchinputcont {
      width: 210px !important;
      margin-right: 30px !important;
  }
  .searchinputcont .input-group-prepend{
      display: inline-block !important;
  }
  .zipdiv {
      display: inline-block !important;
      width: 155px !important;
      padding-top: 2px !important;
      padding-bottom: 2px !important;
  }
  .ee_buttcon {
      display: inline-block !important;
      width: 119px !important;
  }
  .upperdivcdcont{
      margin-top: 61px !important;
      display: inline-block !important;
  }
  .inputfiledcont{
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }*/

  /* find_n_apply */
  /*.contractordascontentmaindiv{
  width: 1150px !important;
  }
  .searchcontfind{
    margin-top: 20px !important;
  }
  .zipinputf{
     margin-right: 20px;
  }
  .searchdivcontf{
     margin-right: 30px !important;
  }
  */
  /* assigned_work */
   /* .assignworksearchddiv{
     width: 90px !important;
     margin-right: 15px;
  }
  .assignworkzipdfdiv{
    width: 72px !important;
  }
  .awoptiondiv select.browser-default.custom-select
  {
      width: 111px !important;
  }
  .assignworksearchbutfdiv {
    width: 80px !important;
   }
   .assignworkuppdiv{
     display: inline !important;
   }
  .assignworksearchbutfdiv{
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .assignworkninediv{
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .assignworkseainddiv{
    padding-left: 4px;
    padding-right: 4px;
  }
  .assignworkseaddiv{
    padding-right: 1px !important;
  }
  .assignworkmaindiv{
      padding-right: 4px !important;
  }
  .assignworkzipinddiv {
    margin-right: 10px !important;
  }*/
  /* work_order_mang */
  /*.wommaindiv{
    width: 697px !important;
    padding-left: 180px !important;
  }
  .womupperdiv {
  display: inline-block !important;
  }
  .searchinputwom {
  width: 200px !important;
  margin-right: 12px !important;
  }
  .cwowom_butt {
  width: 100px !important;
  padding: 0px !important;
  }
  .zipdivwom {
    width: 100px !important;
   }
  .zipinputwom{
  padding-right: 12px !important;
  }*/
  /* find_Contractor */
  /*.upperdivcdclient{
      display: inline-block !important;
  }
  .searchdivclient{
    width: 200px !important;
    margin-right: 10px !important;
  }
  .czipdivf{
    width: 111px !important;
   }
  .rangediv{
     width: 220px !important;
   }
  .rangewidth {
     width: 100% !important;
  }
  .searchclientfind{
    width: 75px !important;
   }
   .clientfindiv{
     padding-left: 170px !important;
     width: 630px !important;
   }
    .invitebutton{
     width:100px !important;
     }*/
    /* client_dashboard */
   /* .upperdivcdc{
      display: inline-block !important;
    }
    .searchinput{
         width: 180px !important;
    }
    .ee_butt{
       padding-left: 6px !important;
       padding-right: 6px !important;

    }
    .clientdascontentmaindiv {
        width: 993px !important;
        padding-left: 180px !important;
     }
     .selectall_checkbox{
        padding-top: 17px !important;
     }*/
    /* .cwo_butt .iwoebutt */
    /*.cwo_butt{
       padding-left: 6px !important;
       padding-right: 6px !important;
    }
    .iwoebutt{
       padding-left: 6px !important;
       padding-right: 6px !important;
    }*/

  /* user_mang */
    /*.navbartopnav{
        padding-left: 0px !important;
    }
    .usermagementdiv{
        width: 575px !important;
        padding-left: 200px !important;
    }
    .side-nav.wide{
      width: 10rem;
    }
    .clientcreateworkorderdiv{
        padding-left: 170px !important;
    }

    .register_div .modal-content {
      width: 395px !important;
      }
      .emailin {
        width: 333px !important;
      }*/
     /* create_work_order*/
     /* .browser-default .custom-select .per{
      width: 85px !important;
      }
      .per{
            width: 85px !important;
      }
      .workordermanagement_wodpop{
        width: 100% !important;
      }

     .selcdiv ,.typekeydiv , .selrdiv, .selwtdiv, .typezcdiv{
     display: inline-block !important;
     padding-left: 0px;
     padding-right: 0px;
     margin-bottom: 0px;
     }
     .inputfieldhome{
      display: inline-block !important;
     }
     .typekeyhome , .zipcodehome{
         width: inherit !important;
     }
     .most_visited_cat_head{
       margin-top: 130px !important;
     }
    .carousel-multi-item {
    margin-bottom: 3rem !important;
    }
    .typekeydiv input[type="text"] , .typezcdiv input[type="text"]{
      height: 39px !important;
    }
  .viewpop{
  margin-left: auto;
  }
  .register_div{
     width: 392px !important;
    }
  .emailin{
  width: 348px !important;
  }
  .navitems{
  width: 100vw !important;
  }
  .controls-top{
  top: 300px !important;
    }
  .mainhomediv{
  width: fit-content;
  }
  .navitem{
  width: 100% !important;
  }
  .navbarcol{
   background-color: #15bcd5;
   }
   .vabut{
     white-space: nowrap;
   }
   .locationtl{
     margin-right: 20px !important;
   }
   .foot_subtitleson{
     margin-right: 20px;
   }
   .contacttl{
     margin-right: 56px;
   }
   .foot_subtitlesup{
      margin-right: 75px;
   }
   .supporttl{
      margin-right: 73px;
   }
   .title_div p{
     text-align: justify;
   }
   .searchbtndiv{
      padding-top: 40px !important;
      padding-bottom: 40px !important;
   }*/
}
/* / Ipad / */
@media (min-width: 767px) and (max-width: 991px){
  /* contractor_dashboard */
 /* ss*/
  /* .searchinputcont{
    width: 200px !important;
    margin-right: 20px !important;
  }
  .ee_buttcon {
    width: 113px !important
  }
  .zipinputcont{
  padding-right: 20px;
 }
 */
 /* find_n_apply */
 /* .searchdivcontf {
   width: 280px !important;
 }
 .zipdivf {
   width: 160px !important;
 }
 .contractordascontentmaindiv{
   width: 1150px !important;
 }
 .searchcontfind{
   margin-top: 20px !important;
 }
 .zipinputf{
    margin-right: 20px;
 }
 .searchdivcontf{
    margin-right: 30px !important;
 }
 */
 /* assigned_work */
 /* .assignworksearchddiv{
    width: 205px !important;
 }
 .assignworkzipdfdiv{
   width: 100px !important;
 }
 .awoptiondiv select.browser-default.custom-select
 {
    width: 112px !important;
 }
 .assignworksearchbutfdiv {
   width: 75px !important;
  }

 */
  /* work_order_mang */
  /*.searchinputwom {
    width: 134px !important;
    margin-right:6px !important;
  }
  .zipdivwom {
    width: 110px !important;
   }
   .cwowom_butt {
      width: 100px;

  }
  .zipinputwom{
    padding-right: 5px !important;
  }*/
    /* find_Contractor */
    /*.searchdivclient {
        width: 145px !important;
    }
    .czipdivf{
      width: 95px !important;

    }
    .searchclientfind{
      margin-left: 8px !important;
      padding-left: 0px;
      padding-right: 0px;
      margin-right: 0px;
      width: 65px !important;

    }
     .ninecoldivclientfind{
       padding-left: 0px !important;

     }
     .searchdivclient{
       margin-right: 10px !important;
     }
      .clientfindiv{
       width: 861px !important;
     }
     .rangewidth{
       width: 70px !important;
     }
     .rangediv{
        padding-right: 0px !important;
        width: 300px !important;
     }
     .upperdivcdclient{
        display: inline-block !important;
     }
  */

     /* client_dashboard */
     /*.searchinput{
        width: 340px !important;
     }
       .cke_editable .cke_editable_themed .cke_contents_ltr .cke_show_borders{
                  background-color:#343a40 !important;
      }
      .cke_editable {
        background-color:#343a40 !important;
        }
    .applicantappiliedwo{
      position: absolute;
      display: flex !important;
    }

    .wodpop{
     width: 100% !important; 
      }
      .clienttab .nav-link {
    padding: .5rem 0px !important;
    margin-right: 8px !important;
    font-size: 12.8px;
    }
    .vabut{
      height: 36px !important;
    }
    .searchbardivcdc{
        display: inline-block !important;
    }
    .perhour_device{
          margin-left: 27px;
    }
    .titlee_div{
          margin-top: 15px;
    }
    .enddate_div{
        margin-top: 32px;
    }
    .perdevice_hour{
          margin-left: 44px;
    }
    .work_div{
          margin-left: 73px;
    }
    .delive_div{
          margin-left: 27px;
    }
    .inputfieldhome input {
    width: 116px !important;
    }
    .navitems{
    padding-right: 2% !important;
    }
    .vabut{
     white-space: nowrap;
   }*/
 
}
/* / Ipad pro / */
@media (min-width: 992px) and (max-width: 1199px) {
  /* contractor_dashboard */
 /* ss*/
  /*.searchinputcont{
    width: 175px !important;
  }
  .zipdiv{
    width: 130px !important;
  }
  */
  /* find_n_apply */
 /* .searchdivcontf {
    width: 280px !important;
  }
  .zipdivf {
    width: 160px !important;
  }
  .contractordascontentmaindiv{
    width: 1150px !important;
  }
   .searchcontfind{
     margin-top: 20px !important;
   }
   .zipinputf{
      margin-right: 20px;
   }
   .searchdivcontf{
      margin-right: 30px !important;
   }*/

   /* assigned_work */
   /*.assignworksearchddiv{
      width: 300px !important;
   }
   .assignworkzipdfdiv{
     width: 130px !important;
   }
   .awoptiondiv select.browser-default.custom-select
   {
      width: 112px !important;
   }
  */
     /* work_order_mang */
   /*.cwowom_butt {
        width: 107px;
   }
   .searchinputwom {
    width: 240px !important;
    margin-right: 30px !important;
    }
    .zipdivwom{
      width: 140px !important;
    }*/

   /* user_mang */
  /* .form-check{
     padding-left: 10px !important;
   }
  */
   /* find_Contractor */
   /*.searchdivclient {
        width: 175px !important;
    }
   .czipdivf{
     width: 95px !important;

   }
   .searchclientfind{
     margin-left: 15px !important;
     width: 75px !important;
   }
   .upperdivcdclient{
      display: inline-block !important;
   }*/

   /* client_dashboard */

    /*.selectall_checkbox {
    padding-left: 42px !important;
    }
    .selectall_checkbox {
      padding-left: 0px !important;
    }
    .clienttab .nav-link {
    padding: .5rem 0px !important;
    margin-right: 8px !important;
    }
    .rangediv {
        width: 340px !important;
    }
    .searchinput{
        width: 340px !important;
    }
    .vabut{
      height: 36px !important;
    }
    .searchbardivcdc{
        display: inline-block !important;
    }
    .wodpop{
     width: 100% !important; 
      }
     .workordermanagement_wodpop{
        width: 100% !important;
      }
      .applicantappiliedwo{
      right: 0px !important;
      width: 400px !important; 
      }
      .vabut{
     white-space: nowrap;
      }*/

}
/* Mac */
@media screen and (min-width: 1600px) and (max-width: 1600px) {

}

/*.selectdrop{
    align-items: flex-start !important;
}
.colpngdiv{
  width: 20px !important;
}
.viewfile_div{
  display: inline-block !important;
  width: 895px !important;
}
.divworkdetail{
  margin-left: 10px !important;
  width: 502px !important;
}

.registercol4{
width: 262px !important;
}
.registercol5{
width:260px !important;
}
.emailin{
  width: 487px;
}
a .nav-link .Ripple-parent {
  background-color: #4285f487 !important;
}
.iconsocial{
text-align: left !important;
}
.list-unstyled{
  display: inline-block;
}
.brown.darken-1{
background-color: #363636 !important;
position: relative !important;
}
.arrowgo{
background-color: #eac923;
}
.footinput{
width: 90%;
}
.carousel-multi-item .controls-top .btn-floating{
border-radius: 0% !important;
background: #35ce3a;
}
.iconft{
color: #eac923 !important;
}
.navitems{
  width: 118px;
}
.navitem{
  width: 90px;
  padding-right: 4% !important;
}
.divworkdetail{
  width: 815px !important;
}
.hrline2{
  height: 1px !important;
}
.timepicker
{
    background-color: #182837 !important;
    border-bottom: none !important;
    margin-top: -25px !important;
    width: 172px;
    padding-left: 10px !important;
}
.uploadcheck {
     background-color: #182837 !important;
     width: 184px;
}
.paddingresponsive {
 margin-bottom: -20px;
}
 .usertable_row
{
    margin-left: 30px !important;
}
.timepicker{
   width: -webkit-fill-available;
}
.showresend{
  margin-left: 500px;

}
/*.adcustrow{
  flex-basis: 100%;
  height: 0;
  padding: 20px;
  margin-bottom: 90px;
}

.srchimg{
   height: 28px;
   margin-right: 10px;
}
*/


/* ********************************************************************************************************************************************************************************** */

/* laptops with hipdi */
@media (min-width: 1200px) and (max-width: 1499px) {
    .searchdiv,.searchdivf{
        width: 280px !important;
    }
    .ninecoldiv {
        padding: 0px 0px 0px 74px;
    }
    .howpay{
       /* width: 540px !important; */
      width: 436px !important;
    }
    .searchinput {
      width: 253px !important;
    }
    .start_dateeeeeee{
        width: 162px !important;
    }
    .pchat{
      height: 650px !important;
    }
    /* find_n_apply */
   /* ss*/
    .searchdivcontf{
      width: 330px !important;
    }
    .zipdivf{
      width: 200px !important;
    }
    .searchcontfind{
      margin-top: 20px !important;
    }
    .zipinputf{
       margin-right: 20px;
    }
    .searchdivcontf{
       margin-right: 30px !important;
    }
    /* client_dashboard */
    .selectall_checkbox{
      padding-left: 0px;
      padding-top: 16px !important;
    }
    .searchinputcont{
     width: 290px !important;
    }
    .zipdiv{
      width: 135px !important;
    }
    .rangediv{
      width: 214px !important;
    }
    .vabut{
       white-space: nowrap;
    }
    .clienttab{
      width: 64vw;
    }
    .clienttab ul{
      width: -webkit-max-content;
      width: max-content;
   }
   .find_apply2{
       margin-top: 90px;
       min-width: 120px;
       width: 20% !important;
   }
    /* .nav-link{
      padding-left: 5px;
      padding-right: 5px;
   }*/
   .ee_buttcon{
      width: 136px !important;
   }
   .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }
}
/* ********************************************************************************************************************************************************************************** */

    /* laptops with touch & mdpi */
@media only screen and (min-width: 1030px)and (max-width: 1366px){
    .pchat{
      height: 545px !important;
    }
    .searchdiv,.searchdivf{
        width: 220px !important;
    }
    .zipdiv{
        width: 180px;
    }
    .ninecoldiv {
        padding: 0px 0px 0px 35px;
    }
    .tencoldiv span {
        padding: 0px 20px 0px 0px;
    }

    .threecoldiv h5{
        font-size: 19px;
    }
    .searchinput {
       width: 225px !important; 
       /* width: 148px !important;*/
    }
    .howpay{
        /* width: 459px !important; */
        width: 355px !important;
    }
    .start_dateeeeeee{
        /*width: 136px  !important;*/
       width: 126px  !important;
    }
    /* find_n_apply */
   /* ss*/
    .searchdivcontf{
      width: 300px !important;
    }
    .zipdivf{
    width: 170px !important;
    }
    /* client_dashboard */
    .selectall_checkbox {
      padding-left: 1px !important;
    }
    .searchinput{
      width: 280px !important;
    }
    /* find_Contractor */
    .searchdivclient {
      width: 270px !important;
    }
    .czipdivf {
      width: 148px !important;
    }
    .col-md-2 .selectall_checkbox .d-flex{
      display: none !important;
    }
    .searchinputcont{
      width: 173px !important;
    }
    .zipdiv {
      width: 135px !important;
    }
    .rangediv{
       width: 214px !important;
    }
   /* .ee_buttcon{
      width: 155px !important;
   }*/
    .wodpop {
    max-width: 1200px;
    width: 950px !important;
    }
}
/* ********************************************************************************************************************************************************************************** */

        /* sir laptop */
@media (min-width: 1500px) and (max-width: 1699px){
  .start_dateeeeeee{
      width: 180px !important;
  }
  .emailin {
    width: 487px !important;
  }
  /* find_n_apply */
 /* ss*/
 .col-md-9 {
      flex: 0 0 71%;
  }
  .searchcontfind{
    margin-top: 20px !important;
  }
  .zipinputf{
     margin-right: 20px;
  }
  .searchdivcontf{
     margin-right: 30px !important;
  }
    /* assigned_work */
  .assignworkninediv{
         padding-right: 70px !important;
  }
     /* client_dashboard */
  .selectall_checkbox{
       padding-left: 0px !important;
       padding-top: 16px !important;
  }
  .modal .modal-full-height .applicantappiliedwo{
      right: 0px !important;
  }
  .vabut{
  white-space: nowrap;
  }
  .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }
}
/* ********************************************************************************************************************************************************************************** */

            /* big desktops */
@media (min-width: 1900px){
  .start_dateeeeeee{
      width: 240px !important;
  }
  .checkboxselectcol{
    margin-top: auto;
  }
  .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }
}
/* ********************************************************************************************************************************************************************************** */

                /* big des */
@media (min-width: 1700px)and (max-width: 1899px){
    .ninecoldiv {
        padding: 0px 0px 0px 156px;
    }
    .searchdiv,.searchdivf{
        width: 510px !important;
    }
    .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }

}
/* ********************************************************************************************************************************************************************************** */

                    /* tab */
@media (min-width: 768px) and (max-width: 1023px) {

}
/* ********************************************************************************************************************************************************************************** */

/* Phone */
@media (max-width: 766px){
  /* contractor Dashboard */
  .contractordascontentmaindiv{
    padding-left: 164px !important;
  }
  .upperdivcdcont{
    display: flex !important;
  }
  .find_apply10{
    width: 80% !important;
  }
  .start_dateeeeeee{
      width: 162px !important;
  }
  .cnewworder_row{
    display: block !important;
  }
  /* dashboard */
  .side-nav.wide .collapsible a {
    padding-left: 11px !important;
    font-size: 11px !important;
  }
  .dashboardimg,.bancolimg,.awimg,.vwimg,.messageimg{
    width: 15px !important;
    margin: 0px 5px 0px 0px !important;
  }
  /* dashboard end */
  /*ss*/
  /* contractor_dashboard */
  .searchinputcont {
      width: 210px !important;
      margin-right: 30px !important;
  }
  .searchinputcont .input-group-prepend{
      display: inline-block !important;
  }
  .zipdiv {
      display: inline-block !important;
      width: 155px !important;
      padding-top: 2px !important;
      padding-bottom: 2px !important;
  }
  .ee_buttcon {
      display: inline-block !important;
      width: 119px !important;
  }
  .upperdivcdcont{
      margin-top: 61px !important;
      display: inline-block !important;
  }
  .inputfiledcont{
    padding-top: 12px !important;
    padding-bottom: 12px !important;
  }

  /* find_n_apply */
  .contractordascontentmaindiv{
  width: 1150px !important;
  }
  .searchcontfind{
    margin-top: 20px !important;
  }
  .zipinputf{
     margin-right: 20px;
  }
  .searchdivcontf{
     margin-right: 30px !important;
  }

  /* assigned_work */
  .assignworksearchddiv{
      width: 90px !important;
     margin-right: 15px;
  }
  .assignworkzipdfdiv{
    width: 72px !important;
  }
  .awoptiondiv select.browser-default.custom-select
  {
      width: 111px !important;
  }
  .assignworksearchbutfdiv {
    width: 80px !important;
  }
  .assignworkuppdiv{
     display: inline !important;
  }
  .assignworksearchbutfdiv{
    padding-left: 0px;
    padding-right: 0px;
    margin-left: 0px;
    margin-right: 0px;
  }
  .assignworkninediv{
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .assignworkseainddiv{
    padding-left: 4px;
    padding-right: 4px;
  }
  .assignworkseaddiv{
    padding-right: 1px !important;
  }
  .assignworkmaindiv{
      padding-right: 4px !important;
  }
  .assignworkzipinddiv {
    margin-right: 10px !important;
  }
  /* work_order_mang */
  .wommaindiv{
    width: 697px !important;
    padding-left: 180px !important;
  }
  .womupperdiv {
  display: inline-block !important;
  }
  .searchinputwom {
  width: 200px !important;
  margin-right: 12px !important;
  }
  .cwowom_butt {
  width: 100px !important;
  padding: 0px !important;
  }
  .zipdivwom {
    width: 100px !important;
  }
  .zipinputwom{
  padding-right: 12px !important;
  }
  /* find_Contractor */
  .upperdivcdclient{
      display: inline-block !important;
  }
  .searchdivclient{
    width: 200px !important;
    margin-right: 10px !important;
  }
  .czipdivf{
    width: 111px !important;
  }
  .rangediv{
     width: 220px !important;
  }
  .rangewidth {
     width: 100% !important;
  }
  .searchclientfind{
    width: 75px !important;
  }
 .clientfindiv{
     padding-left: 170px !important;
     width: 630px !important;
  }
  .invitebutton{
     width:100px !important;
  }
  /* client_dashboard */
  .upperdivcdc{
    display: inline-block !important;
  }
  .searchinput{
       width: 180px !important;
  }
  .ee_butt{
     padding-left: 6px !important;
     padding-right: 6px !important;
  }
  .clientdascontentmaindiv {
      width: 993px !important;
      padding-left: 180px !important;
  }
  .selectall_checkbox{
      padding-top: 17px !important;
  }
  /* .cwo_butt .iwoebutt */
  .cwo_butt{
     padding-left: 6px !important;
     padding-right: 6px !important;
  }
  .iwoebutt{
     padding-left: 6px !important;
     padding-right: 6px !important;
  }
  /* user_mang */
  .navbartopnav{
      padding-left: 0px !important;
  }
  .usermagementdiv{
      width: 575px !important;
      padding-left: 200px !important;
  }
  .side-nav.wide{
    width: 10rem;
  }
  .clientcreateworkorderdiv{
      padding-left: 170px !important;
  }
  .register_div .modal-content {
    width: 395px !important;
  }
  .emailin {
      width: 333px !important;
  }
   /* create_work_order*/
  .browser-default .custom-select .per{
    width: 85px !important;
  }
  .per{
      width: 85px !important;
  }
  .wodpop {
    max-width: 1200px;
    width: 100% !important;
  }
  .workordermanagement_wodpop{
      width: 100% !important;
  }
  .selcdiv ,.typekeydiv , .selrdiv, .selwtdiv, .typezcdiv{
     display: inline-block !important;
     padding-left: 0px;
     padding-right: 0px;
     margin-bottom: 0px;
  }
  .inputfieldhome{
    display: inline-block !important;
  }
  .typekeyhome , .zipcodehome{
       width: inherit !important;
  }
  .most_visited_cat_head{
     margin-top: 200px !important;
  }
  .carousel-multi-item {
     margin-bottom: 3rem !important;
  }
  .typekeydiv input[type="text"] , .typezcdiv input[type="text"]{
    height: 39px !important;
  }
  .viewpop{
    margin-left: auto;
  }
  .register_div{
     width: 392px !important;
  }
  .emailin{
    width: 348px !important;
  }
  .navitems{
    width: 100vw !important;
  }
  .controls-top{
    top: 300px !important;
  }
  .mainhomediv{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
  }
  .navitem{
    width: 100% !important;
  }
  .navbarcol{
     background-color: #15bcd5;
  }
  .vabut{
     white-space: nowrap;
  }
  .locationtl{
     margin-right: 20px !important;
  }
  .foot_subtitleson{
     margin-right: 20px;
  }
  .contacttl{
     margin-right: 56px;
  }
  .foot_subtitlesup{
      margin-right: 75px;
  }
  .supporttl{
      margin-right: 73px;
  }
  .title_div p{
     text-align: justify;
  }
  .searchbtndiv{
      padding-top: 40px !important;
      padding-bottom: 50px !important;
  }
  .upperhomediv{
      padding-right: 15px;
  }
  .inputfieldhome{
    padding-left: 15px;
  }
  .text_div1{
     padding-right: 15px;
     padding-left: 15px;
  }
  .icongroupres{
     text-align-last: center;
  }
  .text_div3{
     padding-right: 15px;
     padding-left: 15px;
  }
  .signup_div{
    padding-top: 20px;
  }
  .iconsocial{
  text-align-last: center;
  }
    .usertable_row
  {
    margin-left: 0px !important;
  }
  .checkboxselectcol{
    display: inline-block;
    width: auto;
  }
  .clienttab ul{
      width: -webkit-max-content;
      width: max-content;
  }
  .navlinkitem{
    padding-right: 6px;
    padding-left: 6px;
  }
  .timepicker{
   width: 76px;
  }  
  .react-datepicker-wrapper{
     display: block;
  }
  .checkboxselectcol{
    padding-right: 20px !important;
  }
  

}
/* ********************************************************************************************************************************************************************************** */

/* Ipad pro */
@media (min-width: 992px) and (max-width: 1199px) {
  .start_dateeeeeee{
      width: 115px  !important;
  }
  .pchat{
      height: 1288px !important;
  }
  /* contractor_dashboard */
  /* ss*/
  .searchinputcont{
    width: 175px !important;
  }
  .zipdiv{
    width: 130px !important;
  }
  /* find_n_apply */
  .searchdivcontf {
    width: 280px !important;
  }
  .zipdivf {
    width: 160px !important;
  }
  .contractordascontentmaindiv{
    width: 1150px !important;
  }
  .searchcontfind{
     margin-top: 20px !important;
  }
  .zipinputf{
      margin-right: 20px;
  }
  .searchdivcontf{
      margin-right: 30px !important;
  }

  /* assigned_work */
  .assignworksearchddiv{
      width: 300px !important;
  }
  .assignworkzipdfdiv{
     width: 130px !important;
  }
  .awoptiondiv select.browser-default.custom-select
  {
     width: 112px !important;
  }
   /* work_order_mang */
  .cwowom_butt {
      width: 107px;
  }
  .searchinputwom {
    width: 240px !important;
    margin-right: 30px !important;
  }
  .zipdivwom{
    width: 140px !important;
  }
   /* user_mang */
  .form-check{
    padding-left: 10px !important;
  }
   /* find_Contractor */
  .searchdivclient {
      width: 175px !important;
  }
  .czipdivf{
    width: 95px !important;
  }
  .searchclientfind{
    margin-left: 15px !important;
    width: 75px !important;
  }
  .upperdivcdclient{
    display: inline-block !important;
  }
   /* client_dashboard */
  .selectall_checkbox {
    padding-left: 42px !important;
  }
  .selectall_checkbox {
    padding-left: 0px !important;
  }
  .clienttab .nav-link {
    padding: .5rem 0px !important;
    margin-right: 8px !important;
  }
  .rangediv {
      width: 340px !important;
  }
  .searchinput{
     width: 340px !important;
  }
  .vabut{
    height: 36px !important;
  }
  .searchbardivcdc{
      display: inline-block !important;
  }
  .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }
  .workordermanagement_wodpop{
     width: 100% !important;
  }
  .applicantappiliedwo{
    right: 0px !important;
    width: 400px !important; 
  }
  .vabut{
    white-space: nowrap;
  }
  .clienttab{
     width: -webkit-max-content;
     width: max-content;
  }
   .ee_buttcon{
      width: 110px !important;
      white-space: nowrap;
  }
  .checkboxselectcol{
    padding-right: 20px !important;
  }

}
/* ********************************************************************************************************************************************************************************** */

    /* Ipad */
@media (min-width: 767px) and (max-width: 991px){
  .start_dateeeeeee{
      width: 115px  !important;
  }
  .pchat{
    height: 750px !important;
  }
  .copyicon{
    /* margin-right: 12px !important; */
  }
   /* contractor_dashboard */
   /* ss*/
  .searchinputcont{
    width: 200px !important;
    margin-right: 20px !important;
  }
  .ee_buttcon {
    width: 113px !important
  }
  .zipinputcont{
    padding-right: 20px;
  }
  /* find_n_apply */
  .searchdivcontf {
    width: 280px !important;
  }
  .zipdivf {
    width: 160px !important;
  }
  .contractordascontentmaindiv{
    width: 1150px !important;
  }
  .searchcontfind{
    margin-top: 20px !important;
  }
  .zipinputf{
    margin-right: 20px;
  }
  .searchdivcontf{
    margin-right: 30px !important;
  }
   /* assigned_work */
  .assignworksearchddiv{
    width: 205px !important;
  }
  .assignworkzipdfdiv{
    width: 100px !important;
  }
  .awoptiondiv select.browser-default.custom-select
  {
    width: 112px !important;
  }
  .assignworksearchbutfdiv {
    width: 75px !important;
  }
  /* work_order_mang */
  .searchinputwom {
    width: 134px !important;
    margin-right:6px !important;
  }
  .zipdivwom {
    width: 110px !important;
  }
  .cwowom_butt {
      width: 100px;
  }
  .zipinputwom{
    padding-right: 5px !important;
  }
  /* find_Contractor */
  .searchdivclient {
      width: 145px !important;
  }
  .czipdivf{
    width: 95px !important;
  }
  .searchclientfind{
    margin-left: 8px !important;
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
    width: 65px !important;
  }
  .ninecoldivclientfind{
     padding-left: 0px !important;
  }
  .searchdivclient{
     margin-right: 10px !important;
  }
  .clientfindiv{
     width: 861px !important;
  }
  .rangewidth{
     width: 70px !important;
  }
  .rangediv{
      padding-right: 0px !important;
      width: 300px !important;
  }
  .upperdivcdclient{
     display: inline-block !important;
  }
   /* client_dashboard */
  .searchinput{
     width: 340px !important;
  }
  .cke_editable .cke_editable_themed .cke_contents_ltr .cke_show_borders{
     background-color:#343a40 !important;
  }
  .cke_editable {
     background-color:#343a40 !important;
  }
  .applicantappiliedwo{
      position: absolute;
      display: flex !important;
  }
  .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }
  .clienttab .nav-link {
    padding: .5rem 0px !important;
    margin-right: 8px !important;
    font-size: 12.8px;
  }
  .vabut{
    height: 36px !important;
    white-space: nowrap;
  }
  .searchbardivcdc{
      display: inline-block !important;
  }
  .perhour_device{
     margin-left: 27px;
  }
  .titlee_div{
     margin-top: 15px;
  }
  .enddate_div{
      margin-top: 32px;
  }
  .perdevice_hour{
     margin-left: 44px;
  }
  .work_div{
     margin-left: 73px;
  }
  .delive_div{
     margin-left: 27px;
  }
  .inputfieldhome input {
    width: 110px !important;
  }
  .navitems{
    padding-right: 2% !important;
  }
  .ninecoldivclientfind{
    margin-left: 15px;
  }
  .clienttab ul{
     width: -webkit-max-content;
     width: max-content;
  }
  .checkboxselectcol{
    padding-left: 6px;
  }
  .selcdiv select, .selrdiv select, .selwtdiv select{
    width: 138px !important;
  }
  
}
/* ********************************************************************************************************************************************************************************** */
/* Mac */
@media (min-width: 1600px) and (max-width: 1600px){
  .start_dateeeeeee {
      width: 189px !important;
  }
  .pchat{
    height: 144vw !important;
  }
  .wodpop {
    max-width: 1200px;
    width: 950px !important;
   }
}

.form-control:focus {
	background-color: white !important;
	color: black !important;
}
.font11 {
	font-size: 11px;
}
.font12 {
	font-size: 12px;
}
.font13 {
	font-size: 13px;
}
.font14 {
	font-size: 14px;
}
.font11Bold {
	font-size: 11px;
	font-weight: bold;
}
.soundimg {
	/* width: 28px; */
	width: 23px;
	cursor: pointer;
}
.settingimg {
	/* width: 25px; */
	width: 20px;
	cursor: pointer;
}
.logoutimg {
	/* width: 25px; */
	width: 20px;
	cursor: pointer;
}
.menubar {
	width: 25px;
	cursor: pointer;
	padding: 0px 0px 0px 110px;
}
.dashboardimg {
	width: 20px;
	margin: 0px 10px 0px 0px;
}
.bancolimg {
	width: 20px;
	margin: 0px 10px 0px 0px;
}
.awimg {
	width: 20px;
	margin: 0px 10px 0px 0px;
}
.vwimg {
	width: 20px;
	font-size: 20px !important;
	margin: 0px 10px 0px 0px;
}
.exportimg {
	position: relative; top: 3px;
}
.messageimg {
	width: 20px;
	margin: 0px 10px 0px 0px;
}
a:hover {
	text-decoration: none;
}
.pngimg {
	width: 35px;
	padding: 0px 0px 10px 0px;
}
.docimg {
	width: 35px;
	padding: 0px 0px 10px 0px;
}
.pdfimg {
	width: 35px;
	padding: 0px 0px 10px 0px;
}
.pngimgicon {
	width: 30px;
	padding-right: 12px;
	margin-bottom: 10px;
}
.docimgicon {
	width: 30px;
	padding-right: 12px;
	margin-bottom: 10px;
}
.pdfimgicon {
	width: 30px;
	padding-right: 12px;
	margin-bottom: 10px;
}
.avatar {
	vertical-align: middle;
	/* width: 35px;
  height: 35px; */
	width: 25px;
	height: 25px;
	border-radius: 50%;
	margin-top: -5px;
}
i {
	cursor: pointer;
}
.activenavitem {
	background-color: #1e2f41;
	height: 50px !important;
}
.nonactivenavitem {
	height: 50px !important;
	background-color: transparent;
	/* background-color:red; */
}
.outlineprofile {
	border: 2px solid #33b5e6;
	vertical-align: middle;
	/* width: 35px;
    height: 35px; */
	width: 25px;
	height: 25px;
	border-radius: 50%;
	margin-top: -5px;
}
.nonoutlineprofile {
	border: none;
	vertical-align: middle;
	/* width: 35px;
    height: 35px; */
	width: 25px;
	height: 25px;
	border-radius: 50%;
	margin-top: -5px;
}
.beforeloginbgcolor {
	background-color: white;
}
.afterloginbgcolor {
	background-color: #16222d;
}
.greenborder {
	border-left: 8px solid #28a745;
}
.orangeborder {
	border-left: 8px solid #ff8c00;
}
.redborder {
	border-left: 8px solid #ff0000;
}
.transparentborder {
	border-left: 8px solid transparent;
}
/* assign work view div */
.check_inbutton {
	border-radius: 0px;
	text-transform: none;
	font-size: 12.5px;
	margin-top: 30px;
	padding: 3px 24px;
}
.markcomplete_but {
	border-radius: 0px;
	text-transform: uppercase;
	font-size: 12.5px;
	margin-top: 30px;
	padding: 3px 14px;
}
.reportan_issuebut {
	border-radius: 0px;
	text-transform: uppercase;
	font-size: 12.5px;
	margin-top: 30px;
	padding: 3px 14px;
	color: white;
}
.reportan_issuebut:hover {
	color: white;
}
.edit_file_div {
	border: 1px solid #2c3f52;
	padding: 10px;
	margin-bottom: 15px;
}
.viewfile_div {
	border: 1px solid #2c3f52;
	padding: 10px;
	margin-bottom: 15px;
}
.workorderlog_div {
	border: 1px solid #2c3f52;
	padding: 10px;
	margin-bottom: 15px;
}
.bordervala {
	border: 1px solid #2c3f52;
	padding: 10px;
	margin: 5px;
	font-size: 11px;
}
.acceptiblecb label {
	color: transparent;
}
.raitextarea {
	background-color: #182837 !important;
	border: none;
	color: white;
	font-size: 11px;
}
.descheck,
.uploadcheck,
.cind,
.cint,
.coutd,
.coutt,
.wdcinout {
	background-color: #182837 !important;
	border: none;
	color: white;
	font-size: 11px;
}
.pngimg1,
.docimg1,
.pdfimg1 {
	width: 100px;
	margin-right: 2px;
	padding-left: 10px;
}
.woaa,
.woaaf,
.woamaxq,
.woaaq,
.woabp,
.woavp {
	background-color: #182837 !important;
	border: none;
	color: white;
	font-size: 11px;
}
.approveandpaybutton {
	border-radius: 0px;
	font-size: 12.5px;
	padding: 6px 14px;
	text-transform: capitalize;
}
.markasincommpletebutton {
	border-radius: 0px;
	font-size: 12.5px;
	padding: 6px 14px;
	text-transform: capitalize;
	color: white;
}
.woaper {
	background-color: #182837 !important;
	border: none;
	color: white;
	font-size: 11px;
}
.woaper option {
	font-size: 11px;
}
/* assign work view div end */
/* top nav */
ul.navbar-nav.mr-auto.navbartopbrand {
	padding: 8px 16px;
	margin-bottom: -6px;
}
.navbar.scrolling-navbar.top-nav-collapse {
	padding-top: 0px;
	padding-bottom: 0px;
}
.side-nav .collapsible {
	margin-top: 0px;
}
/* ul.navbar-nav.ml-auto.navbartopnav{
  padding: 8px 16px;
} */
/* client tab class */
.activectab {
	border-bottom: 2px solid transparent;
	color: white;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 0.5rem;
}
.nonactivectab {
	border-bottom: 2px solid #15bcd5;
	color: #15bcd5 !important;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 0.5rem;
}
.md-form input[type="text"]:not(.browser-default):focus:not([readonly]) {
	border-bottom: 1px solid #1e2f41;
	box-shadow: 0 1px 0 0 #1e2f41;
}
input {
	outline: none;
}
.paddingLeftpush {
	padding-left: 215px;
}
.paddingLeftnone {
	padding-left: 15px;
}
.modal-backdrop {
	/* background-color:#0808084f; */
	background-color: #00000052;
}
.modal.fade.right.show {
	background-color: #212529cf;
	/* background-color: #21252900; */
}
/*  */

/* hide scrollbar */
.modal.fade.right.show::-webkit-scrollbar {
	display: none;
}
/*  */

/*  */
/* HOme CSSS */
.home_page {
	background-color: white !important;
}
.upperhomediv {
	/* clip-path: polygon(0% 0%, 100% 0, 100% 50%, 50% 100%, 0 50%); */
	/*clip-path: polygon(0 0, 100% 1%, 100% 50%, 50% 70%, 0% 50%);*/
	/* height: 700px; */
	height: 850px;
	background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
		url("https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=1200");
	background-size: cover;
	background-repeat: no-repeat;
}
.searchexpdiv {
	width: 188px;
	/* height: 70px; */
	height: 54px;
	padding: 14px;
	font-weight: bold;
	background-color: #15bcd5;
	color: white;
	/*clip-path: polygon(0% 0%, 100% 0, 100% 50%, 50% 100%, 0 50%);*/
}

.home_div2 {
	margin-top: -250px;
	/* margin-top: 70px; */
	margin-bottom: 70px;
	/* padding-top: 70px; */
	padding-top: 20px;
	padding-bottom: 70px;
}
.home_div3 {
	margin-top: 70px;
	margin-bottom: 70px;
	padding-top: 70px;
	padding-bottom: 70px;
	background-color: #f7f8fa;
}
.home_div4 {
	margin-top: 50px;
	margin-bottom: 0px;
	padding-top: 0px;
	padding-bottom: 5px;
}
.home_div5 {
	padding-top: 50px;
	padding-bottom: 50px;
	background-color: #f7f8fa;
}
.inputfieldhome input {
	width: 200px;
	padding: 10px;
}
.selcdiv,
.selrdiv,
.selwtdiv {
	background-color: white !important;
	border: 1px solid #a9a9a9 !important;
}
.selcdiv select,
.selrdiv select,
.selwtdiv select {
	background-color: white !important;
	/*padding: 10px;*/
}
.ccbutton {
	/* border: 1px solid grey; */
	text-align: center;
	margin-bottom: 30px;
	justify-content: center;
}
.cobutton {
	border: 1px solid grey !important;
	padding: 10px;
	width: 150px;
	text-align: center;
	/* background-color:#253273 !important; */
	background-color: white !important;

	/* background-color: #1dbb13 !important; */
	margin-right: -6px !important;
	border-radius: 0px !important;
}
.cobutton:hover {
	color: white !important;
}
.cobutton12 {
	background-color: #253273 !important;
}
.emailin {
	/*  width: 541px; */
}
.headingdivr {
	text-align: center;
	margin-bottom: 30px;
}
.cibutton {
	border: 1px solid grey !important;
	padding: 10px;
	width: 150px;
	text-align: center;
	background-color: white !important;
	border-radius: 0px !important;
}
.registeinput input {
	border-radius: 0px;
}
.registeinput input:focus {
	background-color: white;
}
.registeinput label {
	color: lightgrey;
}
.fb,
.google,
.linkedin {
	color: white;
}
.checkboxdiv {
	display: flex;
	margin-bottom: 30px;
}
.most_visited_cat_head,
.what_peopl_head,
.how_head {
	margin-top: 50px;
	/*margin-bottom: 50px;*/
	margin-bottom: 23px;
	color: #555555 !important;
}
.most_visited_cat_head h1,
.what_peopl_head h1,
.how_head h1 {
	font-weight: bold !important;
}
.text_div1,
.text_div2,
.text_div3 {
	color: #919191;
}
.skills_table,
.location_table,
.vendor_table,
.links_table {
	text-align: left;
	margin-right: 30px;
}
.skills_table th,
.location_table th,
.vendor_table th,
.links_table th {
	padding: 6px 10px !important;
}
.skills_table td,
.location_table td,
.vendor_table td,
.links_table td {
	padding: 6px 10px !important;
}
.caroudiv {
	color: white;
}
.caroudiv h1 {
	font-weight: bold;
}
.caroudiv p {
	font-size: 14px;
}
.firsthomecor .controls-top {
	display: none;
}
.firsthomecor .carousel-indicators li {
	width: 10px;
	height: 10px;
}
.firsthomecor .carousel-indicators .active {
	width: 15px;
	height: 15px;
}
.homeimg_div {
	border: 1px solid #d3d3d391;
	text-align: center;
	justify-content: center;
	padding: 10px;
	width: 208px;
	height: 200px;
	border-radius: 5px;
	margin: 10px;
}
.homeimg_img {
	width: 60px;
	height: 100px;
}
.homeimg_text {
}
.secondhomecor .carousel-indicators {
	display: none;
}
.secondhomecor .controls-top {
	position: absolute;
	top: 180px;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 15;
	display: flex;
	justify-content: center;
	padding-left: 0;
	margin-right: 15%;
	margin-left: 15%;
	list-style: none;
}
.homecircleimg {
	width: 100px;
	height: 100px;
}
.img3 {
	width: 100px;
	height: 100px;
	padding-bottom: 30px;
}
.div4div {
	margin-top: 80px;
}
.div4div h5 {
	color: #555555;
}
.div4col1 {
}
.div4col2 {
}
.div4col3 {
}

/*  */
/* Reguster Css */
.ccbutton {
	/* border: 1px solid grey; */
	text-align: center;
	margin-bottom: 30px;
	justify-content: center;
	color: white;
}
.cobutton {
	border: 1px solid grey !important;
	padding: 10px;
	width: 150px;
	text-align: center;
	/* background-color: #1dbb13 !important; */
	/* background-color:#253273 !important; */
	background-color: white !important;

	margin-right: -6px !important;
	border-radius: 0px !important;
	/* color: white; */
	font-size: 14px;
	text-transform: none;
	box-shadow: none !important;
}
.cobutton:hover,
.cibutton:hover {
	/* color:white !important; */
	/* background-color:#253273 !important; */
	/* border: 1px solid grey !important;
  padding: 10px;
  width: 150px;
  text-align: center; */
	/* background-color: #1dbb13 !important; */
	/* background-color:#253273 !important; */
	/* background-color:white !important; */
	/* color:white !important;
  margin-right: -6px !important;
  border-radius: 0px !important; */
	/* color: white; */
	/* font-size: 14px;
  text-transform: none;
  box-shadow:none; */
	background-color: #253273 !important;
	color: white !important;
}
.cobutton12 {
	background-color: #253273 !important;
	border: 1px solid grey !important;
	padding: 10px;
	width: 150px;
	text-align: center;
	/* background-color: #1dbb13 !important; */
	/* background-color:#253273 !important; */
	/* background-color:white !important; */
	color: white !important;
	margin-right: -6px !important;
	border-radius: 0px !important;
	/* color: white; */
	font-size: 14px;
	text-transform: none;
	box-shadow: none !important;
}
.cibutton {
	border: 1px solid grey !important;
	padding: 10px;
	width: 150px;
	text-align: center;
	/* background-color: white !important; */
	border-radius: 0px !important;
	font-size: 14px;
	text-transform: none;
	box-shadow: none !important;
}
.emailin {
	/* width: 518px; */
}
.headingdivr {
	text-align: center;
	margin-bottom: 30px;
	color: #253273;
	font-family: Arial, Helvetica, sans-serif;
}
.subHeadingdiv {
	text-align: center;
	margin-bottom: 3px;
	color: #253273;
	font-family: Arial, Helvetica, sans-serif;
}
.registeinput input {
	border-radius: 0px;
}
.registeinput label {
	color: #253273;
	font-family: Arial, Helvetica, sans-serif;
}
.fb,
.google,
.linkedin {
	color: white;
	border-radius: 0px;
	text-transform: none;
	font-size: 14px;
}
.checkboxdivres {
	display: flex;
	/* margin-bottom: 20px; */
	margin-top: 20px;
}
.register_div .modal-content {
	width: 600px !important;
	padding: 10px 30px !important;
}
.signbutton {
	border-radius: 0px;
}
/* .regcheck[type="checkbox"].filled-in+label:before{
    visibility: hidden;
}
.regcheck[type="checkbox"].filled-in+label:after{
    visibility: visible;
} */
.regcheck[type="checkbox"].filled-in:not(:checked) + label:before,
label.btn input[type="checkbox"].filled-in:not(:checked) + label:before {
	border: none;
	transform: none;
	transform-origin: unset;
}
.checkboxdivres label {
	color: transparent;
}
.checkboxdivres .form-check-input[type="checkbox"].filled-in:checked + label::before, .checkboxdivres label.btn input[type="checkbox"].filled-in:checked + label::before {
	border-bottom-color: black;
	border-right-color: black;
}
.checkboxdivres .form-check-input[type="checkbox"].filled-in:checked + label::after, .checkboxdivres label.btn input[type="checkbox"].filled-in:checked + label::after{
	border-color: black !important;
}
.cobutton:not([disabled]):not(.disabled):active {
	background-color: #253273 !important;
	color: white !important;
}
.cibutton:not([disabled]):not(.disabled):active {
	background-color: #253273 !important;
	color: white !important;
}
.fb:not([disabled]):not(.disabled):active {
	background-color: #3b5998 !important;
	color: white !important;
}
.google:not([disabled]):not(.disabled):active {
	background-color: #dd4b39 !important;
	color: white !important;
}
.linkedin:not([disabled]):not(.disabled):active {
	background-color: #0082ca !important;
	color: white !important;
}
.fb:hover {
	color: white !important;
}
.google:hover {
	color: white !important;
}
.linkedin:hover {
	color: white !important;
}
.fb:focus,
.google:focus,
.linkedin:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}
/*  */
/* Signin css */
.popup {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	margin: auto;
	background-color: rgba(0, 0, 0, 0.16);
	z-index: 1;
}
.popup\_inner {
	position: absolute;
	left: 25%;
	right: 25%;
	top: 25%;
	bottom: 25%;
	margin: auto;
	border-radius: 10px;
	background: white;
	padding: 20px;
	width: 581px;
	height: 600px;
}
.signindiv .modal-content {
	padding: 10px !important;
	/* width: 500px !important; */
	width: 410px !important;
}
.signindiv .modal-body {
	padding: 10px !important;
}
.headsignin {
	text-align: center;
	margin-bottom: 30px;
}
.signinput input {
	border-radius: 0px;
}
.signinput label {
	color: #253273;
}
.signinput input:focus {
	background-color: white;
}
.buttondivsignin {
	justify-content: center;
}
/* .signcheck[type="checkbox"].filled-in+label:before{
    visibility: hidden;
}
.signcheck[type="checkbox"].filled-in+label:after{
    visibility: visible;
} */
.checkboxdivsign {
	display: flex;
	margin-bottom: 20px;
	margin-top: 20px;
}
.buttondivsignin div {
	margin-bottom: 15px;
}
.fbs,
.googles,
.linkedins {
	color: white;
	border-radius: 0px;
	text-transform: none;
	font-size: 14px;
}
.signcheck[type="checkbox"].filled-in:not(:checked) + label:before,
label.btn input[type="checkbox"].filled-in:not(:checked) + label:before {
	border: none;
	transform: none;
	transform-origin: unset;
}
.checkboxdivsign label {
	color: transparent;
}
.fbs:not([disabled]):not(.disabled):active {
	background-color: #3b5998 !important;
	color: white !important;
}
.googles:not([disabled]):not(.disabled):active {
	background-color: #dd4b39 !important;
	color: white !important;
}
.linkedins:not([disabled]):not(.disabled):active {
	background-color: #0082ca !important;
	color: white !important;
}
.fbs:hover {
	color: white !important;
}
.googles:hover {
	color: white !important;
}
.linkedins:hover {
	color: white !important;
}
.fbs:focus,
.googles:focus,
.linkedins:focus {
	box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0);
}

/*  */
/* Work order management csss */
.ninecoldivwom {
	/* padding: 0px 0px 0px 268px; */
	justify-content: flex-end;
	padding-right: 0px;
}
/* .ninecoldivwom span{
    padding: 0px 15px;
} */
.tencoldivcont span {
	padding: 0px 30px 0px 0px;
}
.wommaindiv {
	background-color: #16222d !important;
	padding: 20px;
	color: white;
	margin-top: 50px;
	/* padding-left: 251px; */
	padding-left: 240px;
}
.inputfiledwom {
	background-color: #1e2f41 !important;
	border: none;
}
.searchdivwom {
	background-color: #1e2f41 !important;
	border: none;
	padding: 5px 5px 5px 15px;
	font-size: 13px;
	color: white;
	/* width:370px; */
	/* height: 38px; */
}
.zipdivwom {
	background-color: #1e2f41 !important;
	border: none;
	padding: 5px 5px 5px 15px;
	width: 175px;
	height: 32px;
	font-size: 13px;
	color: white;
	/* width: 200px;
    height: 38px; */
}
.zipdivwom:focus {
	background-color: white !important;
	color: black !important;
}
.searchinputwom {
	/* width:370px; */
	width: 300px;
	margin-right: 40px;
	height: 32px;
	/* margin-right: 40px;
     padding: 10px; */
}
.zipinputwom {
	padding: 24px 25px 24px 5px;
}
.womupperdiv {
	margin-top: 10px;
	/* margin-bottom: 20px; */
	padding-left: 0px;
}
.wom_table {
	color: white;
	border-collapse: separate;
	border-spacing: 0 25px;
}
.wom_table tr {
	background-color: #1e2f41;
}
.wom_tablecheck label,
.selectall_checkbox label {
	color: #16222d;
}
.wom_table td:last-child {
	/* width: 30px; */
}
.wom_table td:nth-child(2) {
	/* width: 200px; */
}
.wom_table td:first-child {
	padding-left: 30px;
}
.wom_table td {
	padding-bottom: 0px;
}
.wom_tablecheck {
	padding-bottom: 10px;
}
.dollerdiv {
	padding-top: 10px;
	text-align: right;
}
.viewbut {
	font-size: 14px;
	border-radius: 0px;
	width: 128px;
}
.cwowom_butt {
	font-size: 13px;
	line-height: 1.2;
	border-radius: 0px;
}
/* export excel button */
.ee_buttwom {
	border-radius: 0px;
	width: 170px;
	font-size: 14px;
}
/* modelcss */
.wompop .modal-content,
.applicantappiliedwo .modal-content {
	background-color: #1e2f41;
}
.wompop,
.applicantappiliedwo {
	max-width: 1200px;
	width: 950px;
}
.wompop .modal-header,
.applicantappiliedwo .modal-header {
	border: none;
	padding: 0px !important;
}
.editbuttonwom {
	border-radius: 0px !important;
	font-size: 13px;
	text-transform: none;
	line-height: 1.2;
}
.deletebuttonwom {
	border-radius: 0px !important;
	color: white;
	font-size: 13px;
	text-transform: none;
	line-height: 1.2;
}
.deletebuttonwom:hover {
	color: white;
}
.buttondivwom {
	justify-content: flex-end;
}
.applicantwom {
	border-radius: 0px;
	font-size: 13px;
	width: 125px;
	text-transform: none;
	line-height: 1.2;
}
.invitebutwom {
	border-radius: 0px;
	font-size: 13px;
	width: 125px;
	text-transform: none;
	line-height: 1.2;
}
.clientinner_tablewom td:last-child {
	width: auto;
}
.clientinner_tablewom tr {
	border-bottom: 1px solid #384d60;
}
/*  */
/*view application  */
.viewtablediv {
	color: white;
	border-collapse: separate;
	border-spacing: 0 25px;
}
.viewtablediv tr {
	background-color: #1e2f41;
}
.viewpop .modal-content {
	background-color: #1e2f41;
}
.viewpop {
	max-width: 1200px;
	width: 950px !important;
}
.viewpop .modal-header {
	border: none;
	padding: 0px !important;
}
.viewthreediv,
.viewtencdiv {
	padding-left: 0px;
}
.viewninediv {
	display: flex;
	justify-content: flex-end;
}
.viewmaindiv {
	background-color: #16222d !important;
	padding: 20px;
	color: white;
	margin-top: 50px;
	/* padding-left: 251px; */
	padding-left: 250px;
}
/*  */
/* Assigned Work css */
.assignworkmaindiv {
	background-color: #16222d !important;
	padding: 20px;
	color: white;
	margin-top: 50px;
	/* padding-left: 251px; */
	padding-left: 245px;
}
.assignworkninediv {
	display: flex;
	justify-content: flex-end;
}
.assignworkthreediv,
.assignworktencdiv {
	padding-left: 0px;
}
.awoptiondiv {
	margin: 15px 0px 0px 0px;
}
.awoptiondiv select.browser-default.custom-select {
	height: 30px;
	font-size: 12px;
	width: 150px;
	margin-top: 10px;
}
.assignworkseainddiv {
	background-color: #1e2f41 !important;
	border: none;
}
.assignworkseaddiv {
	background-color: #1e2f41 !important;
	border: none;
	color: white;
	font-size: 12px;
	/* width:370px; */
	/* height: 38px; */
}
.assignworksearchddiv {
	/* width:370px; */
	width: 215px;
	margin-right: 15px;
}
.assignworkzipdfdiv {
	background-color: #1e2f41 !important;
	border: none;
	/* width: 200px; */
	width: 130px;
	/* height: 38px; */
	padding: 5px 5px 5px 15px;
	color: white;
	font-size: 12px;
}
.assignworkzipinddiv {
	margin-right: 15px;
	padding: 24px 5px;
	/* margin-right: 20px;
     padding: 10px; */
}
.awsbd {
	margin-right: 15px;
}
.assignworktablediv {
	color: white;
	border-collapse: separate;
	border-spacing: 0 25px;
}
.assignworktablediv tr {
	background-color: #1e2f41;
}
.datespan {
}
.sd {
	padding-right: 20px;
}
.ed {
}
.fileuploade div {
	/* padding: 10px; */
	width: 90px;
}
.range-field input[type="range"] {
	background-color: #1e2f41 !important;
	border: 1px solid #1e2f41 !important;
}
.blankdiv {
	background-color: #1e2f41 !important;
	height: 300px;
}
.range-field input[type="range"] + .thumb {
	background-color: lightgrey !important;
}
.rangedivcontractor {
	color: #1e2f41;
	margin-top: 30px;
	display: flex;
	padding-left: 10px;
	padding-right: 10px;
	width: 300px;
}
.assignworksearchbutfdiv {
	font-size: 13px;
	width: 120px;
	border-radius: 0px;
	text-transform: none;
	font-weight: bold;
	margin-top: 26px;
	line-height: 1.2;
}
.applybutton {
	color: white;
	width: 140px;
	font-size: 13px;
	border-radius: 0px;
	text-transform: none;
	font-weight: bold;
	margin-right: 0px;
}
.pngdiv,
.docdiv,
.pdfdiv {
	text-align: center;
}
.awpop .modal-content,
.applicantappiliedwo .modal-content {
	background-color: #1e2f41;
}
.awpop,
.applicantappiliedwo {
	max-width: 1200px;
	width: 950px !important;
}
.awpop .modal-header,
.applicantappiliedwo .modal-header {
	border: none;
	padding: 0px !important;
}
.assignworkinvitediv,
.assignworkdeletediv {
	font-size: 13px;
	border-radius: 0px;
	line-height: 1.2;
	color: white;
	text-transform: none;
}
.assignworkdeletediv:hover {
	color: white;
}
/*  */
/* Create work order */
.endoftime .md-form {
	margin-top: 0px;
	margin-bottom: 0px;
}
.startoftime .md-form {
	margin-top: 0px;
	margin-bottom: 0px;
}
.endoftime input {
	padding-top: 5px !important;
	padding-left: 5px !important;
	background-color: #1e2f41 !important;
	color: white !important;
	border-bottom: none !important;
}
.startoftime input {
	background-color: #1e2f41 !important;
	color: white !important;
	border-bottom: none !important;
}
.end_time {
	width: auto !important;
	background-color: #1e2f41;
	border: none;
	padding: 6.5px 0px 6.5px 10px;
}
.start_time {
	width: auto !important;
	background-color: #1e2f41;
	border: none;
	padding: 6.5px 0px 6.5px 10px;
}
.pay_btn {
	border-radius: 0px;
	font-size: 12.5px;
}
.worktype_select label.mdb-main-label.active {
	color: white !important;
	/* top: -5px !important; */
}
.worktype_select label {
	/* top: -2px !important; */
}
.worktype_select li > span {
	color: black !important;
	font-size: 12px;
}
.worktype_selectdiv {
	margin-top: 0px;
	margin-bottom: 0px;
	background-color: #1e2f41;
	padding-left: 5px;
	padding-right: 5px;
	height: 32px !important;
	font-size: 12px;
}
.worktype_select input {
	padding-bottom: 10px !important;
	font-size: 12px;
}
.worktype_select input.select-dropdown {
	font-size: 12px;
	border-bottom: none !important;
}
.projecttable td,
.clienttable td {
	color: white !important;
}
.projecttable th,
.clienttable th {
	color: white !important;
}
.comm_div,
.work_div,
.delive_div,
.amountwo_div,
.qaty_div,
.estpay_div,
.amountwo1_div,
.after_div,
.estpay1_div,
.small-styled-input,
.small-input{
	width: 100px;
}
.small-styled-input-half{
	width: 50px;
}
.small-input{
	font-size: 11px;
}

.border-none-with-padding{
	border: none;
	padding: 0.2rem 0.5rem;
}

.full-width{
	width: 100%;
}

.funddiv {
	text-align: center;
	padding-top: 60px;
	font-weight: bold;
}
.variable_div {
	background-color: #415569;
}
.variable_div,
.fundcol {
	padding-top: 15px;
	padding-bottom: 15px;
}
.per option {
	color: white;
}
.per select {
	color: white;
}
.addprojectpop .modal-content,
.add_clientpop .modal-content {
	background-color: #1e2f41;
}
/* .addprojectpop,.add_clientpop {
    max-width: 1200px;
    width: 950px !important;
} */
.addprojectpop .modal-header,
.add_clientpop .modal-header {
	border: none;
}
.projectclose,
.projectsave,
.clientclose,
.clientsave {
	border-radius: 0px;
	font-size: 13px;
	padding: 6px 12px;
}
.addclient,
.addproject {
	border-radius: 0px;
	font-size: 13px;
	padding: 5px 10px;
	margin-top: 0px;
}
.clientcreateworkorderdiv {
	color: white;
	padding: 10px 8rem;
	background-color: #16222d !important;
	margin-top: 63px;
}
.clientcreateworkorderdiv label {
	font-size: 13px;
}
.ccwo_table {
	color: white;
	border-bottom: 2px solid #1c2c3d;
}
.ccwo_table tr,
.ccwo_table th {
	border-bottom: 2px solid #1c2c3d !important;
	font-size: 12.5px;
}
.ccwo_table td,
.ccwo_table th {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}
.clientcreateworkorderdiv .form-control,
.custom-select {
	background-color: #1e2f41;
	border: none;
}
.cke_top {
	/* background-color:#1a202d !important; */
	/* background-color: #001123 !important; */
}
.cke_editable p {
	/* background-color: #000 !important;
    color:#3e3e41 !important; */

	/* color: green !important;
    background-color: blue !important; */
}
a.cke_button {
	/* color:#3e3e41 !important; */
}
.cke_contents,
.cke_bottom,
.cke_wysiwyg_frame,
.cke_wysiwyg_div {
	/* background-color:#001123 !important; */
}
div#cke_editor1,
div#cke_editor2,
div#cke_editor3 {
	border: none;
}
iframe.cke_wysiwyg_frame.cke_reset html,
iframe.cke_wysiwyg_frame.cke_reset body {
	/* background-color:#1e2f41 !important; */
}
.fileuploddiv {
	/* width: 650px; */
	width: 550px;
	height: 110px;
	border: 2px solid #1c2c3d;
	text-align: center;
	padding: 40px 0;
	margin-top: 10px;
	/* line-height:100px; */
}
.documentlist {
	padding: 40px 1px;
}
.hrline2 {
	width: 100%;
	color: black;
	height: 2px;
	margin: 10px 0px;
	background-color: #2c3f52;
}
.hrline {
	width: 100%;
	color: black;
	height: 2px;
	margin: 10px 0px;
	background-color: #1e2f41;
}
.sub_canclebuttondiv {
	padding-bottom: 30px;
}
.clientdascontentmaindiv,
.clientfindiv {
	margin-top: 63px;
}
.datepickerdiv {
	/* padding: 0px 56px; */
	padding: 0px 110px 0px 10px;
}
.checkboxlable label {
	color: transparent;
}
.clientcreateworkorderdiv input {
	font-size: 12px;
	border-radius: 0px;
	color: white;
}
.clientcreateworkorderdiv select {
	font-size: 12px;
	border-radius: 0px;
	color: white;
}
.iwoebutt {
	font-size: 13px;
	border-radius: 0px;
}
.sandp {
	font-size: 13px;
	border-radius: 0px;
	line-height: 1.3;
}
.sad {
	font-size: 13px;
	border-radius: 0px;
	line-height: 1.3;
}
.cancle_button {
	color: white;
	font-size: 13px;
	border-radius: 0px;
	line-height: 1.3;
}
.cancle_button:hover {
	color: white;
}
.ccwadd {
	margin-top: 0px;
	font-size: 13px;
	border-radius: 0px;
	line-height: 1.3;
}
/* .css-nrmngd-DropDown{
  background-color: #1e2f41 !important;
  border: none !important;
}

.css-12zlm52-ReactDropdownSelect,.css-1uqlql8-DropDown{
  border: none !important;
  background-color: #1e2f41 !important;
}
.css-148o527-ItemComponent{
  border-bottom: none !important;
} */
.selectdrop:focus {
	box-shadow: none !important;
}
.selectdrop .react-dropdown-select-dropdown {
	background-color: #1e2f41 !important;
	border: none !important;
	font-size: 12px;
}
.selectdrop .react-dropdown-select-content {
	color: white !important;
	font-size: 12px;
	padding-top: 7px;
}
.selectdrop span.react-dropdown-select-item {
	border-bottom: none !important;
	color: white !important;
	font-size: 12px;
}
.selectdrop {
	background-color: #1e2f41 !important;
	border: none !important;
}

@media (max-width: 766px) {
	.clientcreateworkorderdiv {
		width: 690px !important;
	}
}

/*  */
/* Client Dashboard content */
.profilepop .modal-content {
	background-color: #16222d !important;
	box-shadow: none;
}
.profilepop .modal-header {
	border-bottom: none;
}
.profilepop .modal-header h5 {
	font-weight: bold;
}
.profilepop .modal-footer {
	border-top: none;
}
.profilepop {
	max-width: 1200px;
	width: 950px !important;
}
.profilepop label {
	font-size: 12px;
}
.profilepop input {
	background-color: #1e2f41;
	border: none;
	font-size: 12px;
	color: white;
}
.ninecoldiv {
	padding: 0px 0px 0px 70px;
	/* padding: 0px 0px 0px 149px; */
}
.tencoldiv span {
	padding: 0px 30px 0px 0px;
}
.clientdascontentmaindiv {
	background-color: #16222d;
	padding: 20px;
	color: white;
}
.inputfiled {
	background-color: #1e2f41 !important;
	border: none;
}
.searchdiv {
	background-color: #1e2f41 !important;
	border: none;
	color: white !important;
	font-size: 13px;
	/* width:370px; */
	/* height: 38px; */
}
.zipdiv {
	background-color: #1e2f41 !important;
	border: none;
	width: 200px;
	height: 38px;
	color: white !important;
}
.zipdiv:focus {
	background-color: white !important;
	color: black !important;
}
.searchinput {
	/* width:370px; */
	width: 326px;
	margin-top: 10px !important;
	margin-bottom: 0px !important;
	/* height: 39px; */
	margin-right: 10px;
	height: 32px;
	/* margin-right: 40px;
     padding: 10px; */
}
.zipinput {
	/* margin-right: 15px; */
	padding: 10px;
	font-size: 13px;
}
.cwo_butt {
	/* margin-right: 20px; */
	margin-top: 10px;
	border-radius: 0px;
	font-size: 13px;
	border-radius: 5px;
}
.ee_butt {
	margin-top: 10px;
	border-radius: 0px;
	font-size: 13px;
}
.upperdivcdc {
	margin-bottom: 20px;
}
.client_table {
	color: white;
	border-collapse: separate;
	border-spacing: 0 25px;
}
.client_table tr {
	background-color: #1e2f41;
}
.client_tablecheck label,
.selectall_checkbox label {
	color: #16222d;
}
.selectall_checkbox {
	/* padding-left: 100px; */
	/* padding-left: 488px; */
	/* padding-left: 516px; */
	/* padding-left: 652px; */
	/* padding-left: 755px; */
	/* padding-left: 820px; */
	/* padding-left: 1036px; */
}
.date_table {
	/* width: 116px; */
	width: 175px;
	font-size: 11px;
}
.client_table td:last-child {
	/* width: 30px; */
	width: 265px;
}
.client_table td:first-child {
	/* width: 10px; */
}
.client_table td:nth-child(2) {
	/* width: 310px; */
}
.client_table td {
	padding-bottom: 0px !important;
}
.form-check-input[type="checkbox"] + label:before {
	/* height: 25px;
    width: 25px; */
	height: 16px;
	width: 16px;
	background-color: #41566a;
	border: none;
}
.copyicon {
	font-size: 18px !important;
	margin-top: 4px;
}
.copybutton {
	font-size: 12px !important;
	margin-top: 1px;
}
.form-check-input[type="checkbox"]:checked + label:before {
	background-color: transparent;
}
.client_tablecheck {
	padding-bottom: 10px;
	justify-content: center;
}
.dollerdiv {
	padding-top: 10px;
	text-align: right;
}
.wodpop .modal-content,
.applicantappiliedwo .modal-content,
.workordermanagement_wodpop .modal-content {
	background-color: #1e2f41;
	box-shadow: none;
}
.applicantappiliedwo,
.workordermanagement_wodpop {
	max-width: 1200px;
	width: 950px !important;
}
.clientinner_table td:last-child {
	width: auto;
}
.clientinner_table table th {
	padding-bottom: 6px !important;
	padding-top: 6px !important;
	font-size: 12px;
}
.clientinner_table table td {
	padding-bottom: 6px !important;
	padding-top: 6px !important;
	font-size: 12px;
}
.font12 {
	font-size: 12px;
}
.wodpop .modal-header,
.applicantappiliedwo .modal-header,
.workordermanagement_wodpop .modal-header {
	border: none;
	padding: 0px !important;
}
.editbutton {
	border-radius: 0px !important;
	font-size: 13px;
	text-transform: none;
	line-height: 1.2;
}
.deletebutton {
	border-radius: 0px !important;
	color: white;
	font-size: 13px;
	text-transform: none;
	line-height: 1.2;
}
.deletebutton:hover {
	color: white !important;
}
.invitebut {
	border-radius: 0px;
	font-size: 13px;
	width: 125px;
	text-transform: none;
	line-height: 1.2;
}
.applicantbut {
	border-radius: 0px;
	font-size: 13px;
	text-transform: none;
	line-height: 1.2;
}
.butuondiv {
	justify-content: flex-end;
}
.buttondivpop {
	padding-left: 100px;
	padding: 0px 0px 0px 100px;
}
.clientinner_table tr {
	border-bottom: 1px solid #384d60;
}
.applicantappiliedwo_table {
	border: none;
	color: white;
	border-collapse: separate;
	border-spacing: 0 25px;
}
.applicantappiliedwo_table tr {
	background-color: #182837;
}
.applicantappiliedwo_table td {
	border-top: none;
	padding-bottom: 2px !important;
}
.assignbutton {
	color: white;
	font-size: 12px;
	white-space: nowrap;
	min-width: 120px;
	max-width: -webkit-fit-content;
	max-width: -moz-fit-content;
	max-width: fit-content;
	padding: 4px 8px;
	margin-right: 0;
	border-radius: 0;
}
.assignbutton:active {
	background-color: transparent;
	box-shadow: none;
}
.assignbutton:hover {
	color: white;
}
.dollercont {
	padding-left: 10px;
}
.hourlycont {
	padding-left: 10px;
}
.miles {
	color: #7456f8;
	font-size: 20px;
	padding-right: 35px;
	display: none;
}
.detailbut {
	text-transform: none;
	width: -webkit-max-content;
	width: max-content;
	line-height: 1.5;
	border-radius: 5px;
}
/* .recruit-btn {
	background: white !important;
} */
.recruit-btn:disabled {
	background: #a3a3a3 !important;
}
.recruit-tm {
  font-size: 8px;
}
.vabut {
	margin: 14px 7px 7px 7px;
	height: 32px;
	border-radius: 0px;
	padding: 10px 10px !important;
	line-height: 1;
}
.clienttab {
	padding-left: 0px;
	padding-right: 0px;
}
.updivcdc {
	padding-right: 0px;
}
.pngdivv {
	width: 120px;
	text-align: center;
}
.docdivv {
	width: 75px;
	text-align: center;
}
.pdfdivv {
	width: 75px;
	text-align: center;
}
.profileclientphoto {
	width: 75px;
	height: 75px;
	vertical-align: middle;
	border-radius: 50%;
}
.clientappphoto {
	width: 80px;
	height: 80px;
	vertical-align: middle;
	border-radius: 50%;
}

/* Responsive */
/* Ipad pro */
@media (min-width: 992px) and (max-width: 1199px) {
	.clientdascontentmaindiv {
		width: 1023px !important;
	}
	.selectall_checkbox {
		/* padding-left: 74px !important; */
		/* padding-left: 206px !important; */
		padding-top: 17px;
	}
	/* .clienttab .nav-link{
    padding: .5rem 10px !important;
  } */
	.searchinput {
		width: 260px;
	}
}
/* Ipad */
@media (min-width: 767px) and (max-width: 991px) {
	.clientdascontentmaindiv {
		width: 975px !important;
	}
	/* .ninecoldiv{
    display: block !important;
  } */
	.selectall_checkbox {
		/* padding-left: 26px !important; */
		/* padding-left: 160px !important; */
		padding-top: 17px;
	}
	/* .clienttab .nav-link{
    padding: .5rem 10px !important;
  } */
	.searchinput {
		width: 260px;
	}
}
/* Phone */
@media (max-width: 766px) {
	.clientdascontentmaindiv {
		width: 375px;
	}
	.navbartopnav {
		padding: 10px;
	}
	.selectall_checkbox {
		/* padding-left: 10px; */
	}
}
/* tab */
@media (min-width: 768px) and (max-width: 1023px) {
}
/* big des */
@media (min-width: 1700px) and (max-width: 1899px) {
}
/* big desktops */
@media (min-width: 1900px) {
	.selectall_checkbox {
		/* padding-left: 150px; */
		/* padding-left: 870px; */
		/* padding-left: 1000px; */
		/* padding-left: 1088px; */
	}
}
/* sir laptop */
@media (min-width: 1500px) and (max-width: 1699px) {
	.selectall_checkbox {
		/* padding-left: 755px; */
		/* padding-left: 736px; */
		/* padding-left: 512px !important; */
		/* padding-left: 525px !important; */
		/* padding-left: 478px !important; */
	}
	.searchinput {
		width: 303px !important;
	}
}
/* laptops with touch & mdpi */
@media only screen and (min-width: 1030px) and (max-width: 1366px) {
	.selectall_checkbox {
		/* padding-left: 55px; */
		/* padding-left: 229px !important; */
		/* padding-left: 380px !important; */
		/* padding-left: 240px !important; */
	}
}
/* laptops with hipdi */
@media (min-width: 1200px) and (max-width: 1499px) {
	.selectall_checkbox {
		/* padding-left: 82px; */
		/* padding-left: 389px; */
		/* padding-left: 540px; */
		/* padding-left: 400px; */
	}
}

/*  */
/* client dashboard */
.navbartop {
	padding: 0px;
	background-color: #182837;
}
.navbartopbrand {
	background-color: #16222d;
	padding: 1rem 1rem;
}
.navbartopnav {
	padding: 0.5rem 1rem;
}
.sidenavsw9,
.scrollbar-container.ps {
	background-color: #182837;
}
.side-nav.wide {
	/* margin-top: 65px; */
	margin-top: 49px;
}
#sidenav-overlay {
	background-color: rgba(0, 0, 0, 0);
}
.togglebutton {
	background-color: transparent !important;
	margin-left: 68px;
}
.navbartopnav li.nav-item {
	padding: 0px 15px;
}
.navbar.scrolling-navbar {
	padding-top: 0px;
	padding-bottom: 0px;
}
.sidenavsw9 a {
	height: 50px !important;
	padding-top: 6px;
}

/*  */
/* client find */
.selectwo {
	font-size: 12px;
}
.selectwo option {
	font-size: 12px;
}
.invitepop .modal-content {
	background-color: #16222d !important;
	box-shadow: none;
}
.invitepop .modal-header {
	border-bottom: none;
}
.invitepop .modal-header h5 {
	font-weight: bold;
}
.invitepop .modal-footer {
	border-top: none;
}
.invitepop label {
	font-size: 12px;
}
.invitepop input {
	background-color: #1e2f41;
	border: none;
	font-size: 12px;
	color: white;
}
.clientfindiv {
	background-color: #16222d !important;
	/* padding-left: 251px; */
	padding-left: 226px;
}
.upperdivcdclient {
	padding: 10px 10px 10px 0px;
	color: white;
}
.clientfindiv {
	color: white;
}
.ninecoldivclientfind {
	display: flex;
	justify-content: flex-end;
	/* padding-left: 200px; */
}
.optiondivclient {
	margin: 15px 0px;
}
.optiondivclient select.browser-default.custom-select {
	height: 35px;
	font-size: 13px;
	border-radius: 0px;
}
.cinputfind {
	background-color: #1e2f41 !important;
	border: none;
}
.csearchdivf {
	background-color: #1e2f41 !important;
	border: none;
	color: white;
	font-size: 13px;
	/* width:370px; */
	/* height: 38px; */
}
.czipdivf {
	background-color: #1e2f41 !important;
	border: none;
	padding: 5px;
	width: 175px;
	color: white;
	font-size: 13px;
	height: 32px;
	/* width: 200px;
    height: 38px; */
}
.czipdivf:focus {
	background-color: white !important;
	color: black !important;
}
.zipclientdiv {
	padding: 24px 5px;
}
.czipinputf {
	margin-right: 20px;
	padding: 10px;
}
.clientfind_table {
	color: white;
	border-collapse: separate;
	border-spacing: 0 25px;
}
.clientfind_table tr {
	background-color: #1e2f41;
	padding-bottom: 0px;
}
.clientfind_table td {
	padding-bottom: 5px !important;
}
.datespan {
}
.sd {
	padding-right: 20px;
}
.ed {
}
.fileuploadec div {
	padding: 10px;
}
.range-field input[type="range"] {
	background-color: #1e2f41 !important;
	border: 1px solid #1e2f41 !important;
}
.blankdiv {
	background-color: #1e2f41 !important;
	height: 300px;
}
.range-field input[type="range"] + .thumb {
	background-color: lightgrey !important;
}
.rangediv {
	color: #1e2f41;
	margin-top: 30px;
	display: flex;
	padding-left: 10px;
	padding-right: 10px;
	width: 300px;
}
.requiredField {
	color: red !important;
	font-size: 18px !important;
	padding-left: 3px;
}
.searchdivclient {
	width: 370px;
	height: 32px;
	margin-right: 20px;
	/* padding-right: 117px; */
}
.invitebutton {
	color: white;
	width: 150px;
	font-size: 13px;
	border-radius: 0px;
	text-transform: none;
	font-weight: bold;
}
.invitebuttonpop {
	color: white;
	font-size: 13px;
	border-radius: 0px;
	text-transform: none;
	font-weight: bold;
}
.canbutpopcf {
	border-radius: 0px;
	color: white;
	font-size: 12.5px;
	text-transform: none;
}
.canbutpopcf:hover {
	color: white;
}
.searchclientfind {
	font-size: 13px;
	width: 110px;
	border-radius: 0px;
	text-transform: none;
	font-weight: bold;
	margin-top: 15px;
	margin-bottom: 15px;
	line-height: 1.2;
}
.chip {
	/* display: inline-block;
    height: 32px;
    padding: 0 12px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    font-size: 13px;
    font-weight: 500;
    line-height: 32px;
    color: rgba(0,0,0,0.6);
    cursor: pointer;
    background-color: #eceff1;
    border-radius: 16px;
    transition: all 0.3s linear; */

	display: inline-block;
	height: 25px;
	padding: 0 12px;
	margin-right: 1rem;
	margin-bottom: 5px;
	font-size: 11px;
	font-weight: 500;
	line-height: 25px;
	color: white;
	/* cursor: pointer; */
	background-color: #1e2f41;
	/* background-color: red; */
	border-radius: 16px;
	transition: all 0.3s linear;
}
.chip:focus,
.chip:hover {
	background-color: #1e2f41 !important;
}
.chip1 {
	/* display: inline-block;
    height: 32px;
    padding: 0 12px;
    margin-right: 1rem;
    margin-bottom: 1rem;
    font-size: 13px;
    font-weight: 500;
    line-height: 32px;
    color: rgba(0,0,0,0.6);
    cursor: pointer;
    background-color: #eceff1;
    border-radius: 16px;
    transition: all 0.3s linear; */

	display: inline-block;
	height: 25px;
	padding: 0 12px;
	margin-right: 1rem;
	margin-bottom: 1px;
	font-size: 11px;
	font-weight: 500;
	line-height: 25px;
	color: white;
	/* cursor: pointer; */
	background-color: #16222c;
	/* background-color: red; */
	border-radius: 16px;
	transition: all 0.3s linear;
}
.chip1:focus,
.chip1:hover {
	background-color: #16222c !important;
}
.font14 {
	font-size: 14px;
}
.notficationpop .modal-content {
	background-color: #1e2f41;
	box-shadow: none;
	color: white;
}
.notficationpop .modal-header {
	border-bottom: none;
	margin-bottom: -20px;
}
/* .notification_table{
    border:none;
    color: white;
     border-collapse:separate;
    border-spacing:0 25px;
}
.notification_table tr{
    background-color: #182837;

}
.notification_table td{
    border-top: none;
    padding-bottom: 2px !important;
} */
.textnotificatondiv {
	background-color: #182837;
	padding: 10px;
}
.namenotificatondiv {
	display: flex;
	font-size: 12px;
}
.rangewidth {
	/* width: 130%; */
}
.findclientphoto {
	width: 75px;
	height: 75px;
	border-radius: 50%;
	vertical-align: middle;
}

/*  */
/* Client Profile */
.clientprofilediv {
	background-color: #16222d !important;
	padding: 20px;
	color: white;
	margin-top: 50px;
	padding-left: 240px;
}
.dashdiv {
	padding-left: 10px;
	padding-right: 10px;
}
.editcprofile {
	border-radius: 0px;
	width: 150px;
	line-height: 1.3;
	font-size: 13px;
	margin-right: 10px;
}
.editprofilepopclient .modal-content {
	background-color: #16222d !important;
	box-shadow: none;
	color: white;
}
.editprofilepopclient .modal-header {
	border-bottom: none;
}
.editprofilepopclient .modal-header h5 {
	font-weight: bold;
}
.editprofilepopclient .modal-footer {
	border-top: none;
}
.editprofilepopclient {
	max-width: 1200px;
	width: 950px !important;
}
.editprofilepopclient label {
	font-size: 12px;
}
.editprofilepopclient input {
	background-color: #1e2f41;
	border: none;
	border-radius: 0px;
	font-size: 12px;
	color: white;
}
.custom-file {
	background-color: #1e2f41;
	border: none;
}
.profilepicc {
	width: 200px;
	height: 200px;
}
.profile-col-padding {
	padding: 0px 40px;
}
.gobutc {
	border-radius: 0px;
	margin-top: 0px;
	line-height: 1.2;
	font-size: 12px;
}
.submitbutc {
	border-radius: 0px;
	font-size: 12.5px;
}
.canbutc {
	border-radius: 0px;
	color: white;
	font-size: 12.5px;
}
.canbutc:hover {
	color: white;
}
.conprofilepiccdiv {
	padding-bottom: 40px;
}
.editprofilepopclient .form-control:disabled,
.editprofilepopclient .form-control[readonly] {
	background-color: #1e2f41;
}

/*  */
/* Contractor Dashboard */
/* .ninecoldivcont{
    padding: 0px 0px 0px 268px;
} */
/* .ninecoldivcont span{
    padding: 0px 15px;
} */
/* .tencoldivcont span{
    padding: 0px 30px 0px 0px;
}
.contractordascontentmaindiv{
    background-color:#16222d;
    padding: 20px;
    color: white;
}
.inputfiledcont{
    background-color: #1e2f41 !important;
    border:none;
} */
.searchdiv {
	/* background-color: #1e2f41 !important;
    border:none; */
	/* width:370px; */
	/* height: 38px; */
}
.zipdiv {
	/* background-color: #1e2f41 !important;
    border:none;
    padding: 5px;
    width: 175px; */
	/* width: 200px;
    height: 38px; */
}
.searchinputcont {
	/* width:370px;
     margin-right: 40px; */
	/* margin-right: 40px;
     padding: 10px; */
}
/* .zipinputcont{
    padding: 24px 5px;
}
.upperdivcdcont{
    margin-bottom: 20px;
}
.contractor_table{
    color: white;
     border-collapse:separate;
    border-spacing:0 25px;
}
.contractor_table tr{
    background-color: #1e2f41;
}
.contractor_tablecheck label,.selectall_checkbox label{
    color:#16222d;
}
.selectall_checkboxcont{
    padding-left: 100px;
}
.date_tablecont{
    width: 116px;
    font-size: 11px;
}
.contractor_table td:last-child{
 width: 30px;
}
.contractor_table td:nth-child(2){
 width: 310px;
}
.contractor_table td{
    padding-bottom: 0px;
}
.form-check-input[type="checkbox"]+label:before{
    height: 25px;
    width: 25px;
    background-color: #41566a;
    border: none;
}
.form-check-input[type="checkbox"]:checked+label:before{
    background-color: transparent;
}
.contractor_tablecheck{
    padding-bottom: 10px;
}
.dollerdiv{
    padding-top: 10px;
} */

/*  */
/* Contractor Dashboard content */
.ninecoldivcont {
	/* padding: 0px 0px 0px 268px; */
	justify-content: flex-end;
	padding-right: 0px;
}
.applypop .modal-content,
.applypop2 .modal-content {
	background-color: #1e2f41;
}
.applypop .modal-footer,
.applypop2 .modal-footer {
	border-top: none;
}
.applypop2 {
	text-align: left;
}
.bidamount {
	background-color: #16222c !important;
	border: none;
	color: white;
}
.bidamount:focus {
	background-color: white !important;
	color: black !important;
}
.commenttextarea {
	background-color: #16222c !important;
	border: none;
	color: white;
}
.closeapply {
	border-radius: 0px;
	font-size: 13px;
}
.submitapply {
	border-radius: 0px;
	font-size: 13px;
}
.bidamount2 {
	background-color: #16222c !important;
	border: none;
	color: white;
}
.bidamount2:focus {
	background-color: white !important;
	color: black !important;
}
.commenttextarea2 {
	background-color: #16222c !important;
	border: none;
	color: white;
}
.closeapply2 {
	border-radius: 0px;
	font-size: 13px;
}
.submitapply2 {
	border-radius: 0px;
	font-size: 13px;
}
/* .ninecoldivcont span{
    padding: 0px 15px;
} */
.tencoldivcont span {
	padding: 0px 30px 0px 0px;
}
.contractordascontentmaindiv {
	background-color: #16222d !important;
	padding: 20px;
	color: white;
	margin-top: 50px;
	/* padding-left: 251px; */
	padding-left: 226px;
}
.inputfiledcont {
	background-color: #1e2f41 !important;
	border: none;
}
.searchdiv {
	background-color: #1e2f41 !important;
	border: none;
	padding: 5px 5px 5px 15px;
	font-size: 13px;
	color: white;
	/* width:370px; */
	/* height: 38px; */
}
.zipdiv {
	background-color: #1e2f41 !important;
	border: none;
	padding: 5px 5px 5px 15px;
	width: 175px;
	height: 35px;
	font-size: 12px;
	color: white;
	/* width: 200px;
    height: 38px; */
}
.searchinputcont {
	width: 370px;
	margin-right: 40px;
	height: 34px;
	/* margin-right: 40px;
     padding: 10px; */
}
.zipinputcont {
	padding: 24px 25px 24px 5px;
}
.upperdivcdcont {
	margin-top: 10px;
	/* margin-bottom: 20px; */
	padding-left: 0px;
}
.contractor_table {
	color: white;
	border-collapse: separate;
	border-spacing: 0 25px;
}
.contractor_table tr {
	background-color: #1e2f41;
}
.contractor_tablecheck label,
.selectall_checkbox label {
	color: #16222d;
}
/* .selectall_checkboxcont{
    padding-left: 100px;
} */
/* .date_tablecont{
    width: 116px;
    font-size: 11px;
} */
.contractor_table td:last-child {
	width: 30px;
}
.contractor_table td:nth-child(2) {
	width: 200px;
}
.contractor_table td:first-child {
	padding-left: 30px;
}
.contractor_table td {
	padding-bottom: 0px;
}
.form-check-input[type="checkbox"] + label:before {
	/* height: 25px;
    width: 25px; */
	height: 20px;
	width: 20px;
	background-color: #41566a;
	border: none;
}
.form-check-input[type="checkbox"]:checked + label:before {
	background-color: transparent;
}
.contractor_tablecheck {
	padding-bottom: 10px;
}
.dollerdiv {
	padding-top: 10px;
	text-align: right;
}
/* contractor apply button */
.coapplybut {
	text-transform: none;
	border-radius: 0px;
	width: 100px;
	font-size: 13px;
	/* line-height: 1.2; */
}
/* export excel button */
.ee_buttcon {
	border-radius: 0px;
	width: 170px;
	font-size: 13px;
	/* line-height: 1.2; */
}
.uppertwodivcdc a {
	color: white;
	padding-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 0.5rem;
	margin-right: 15px;
}
.uppertwodivcdc a:hover {
	/* border-bottom: 2px solid #15bcd5;
    color: #15bcd5;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: .5rem;
      margin-right: 15px; */
}
.uppertwodivcdc .tab-content {
	padding: 0px 16px 16px 16px;
}

/*  */
/* Contractor find */
.zipselect_con {
	font-size: 12px;
}
.zipselect_con option {
	/* color: white !important; */
	color: #6d757d !important;
	font-size: 12px;
}
.ninecoldivcontfind {
	display: flex;
	justify-content: flex-end;
}
.optiondiv {
	margin: 15px 0px;
}
.optiondiv select.browser-default.custom-select {
	height: 35px;
	font-size: 12px;
	/* color: white; */
	color: #6d757d;
}
.inputfind {
	background-color: #1e2f41 !important;
	border: none;
}
.searchdivf {
	background-color: #1e2f41 !important;
	border: none;
	color: white;
	font-size: 12px;
	/* width:370px; */
	/* height: 38px; */
}
.searchdivcontf {
	width: 370px;
	margin-right: 20px;
	height: 30px;
}
.zipdivf {
	background-color: #1e2f41 !important;
	border: none;
	width: 200px;
	height: 30px;
	padding: 5px 5px 5px 15px;
	color: white;
	font-size: 12px;
}
.zipdivf:focus {
	background-color: white !important;
	color: black !important;
}
.searchinputf {
	margin-right: 40px;
	padding: 10px;
}
.zipinputf {
	padding: 24px 5px;
	/* margin-right: 20px;
     padding: 10px; */
}
.contractorfind_table {
	color: white;
	border-collapse: separate;
	border-spacing: 0 25px;
}
.contractorfind_table tr {
	background-color: #1e2f41;
}
.contractorfind_table td {
	padding-bottom: 6px !important;
}
/* .contractorfind_table tr td:nth-child(1){
  width: 900px!important;
}
.contractorfind_table tr td:nth-child(2){
  width: 10% !important;
} */
.pngimgf {
	width: 32px;
	padding: 0px 0px 10px 0px;
}
.docimgf {
	width: 32px;
	padding: 0px 0px 10px 0px;
}
.pdfimgf {
	width: 32px;
	padding: 0px 0px 10px 0px;
}
.datespan {
}
.sd {
	padding-right: 10px;
}
.ed {
}
.fileuploade div {
	/* padding: 10px; */
	width: 90px;
}
.range-field input[type="range"] {
	background-color: #1e2f41 !important;
	border: 1px solid #1e2f41 !important;
}
.blankdiv {
	background-color: #1e2f41 !important;
	height: 300px;
}
.range-field input[type="range"] + .thumb {
	background-color: lightgrey !important;
}
.rangedivcontractor {
	color: #1e2f41;
	margin-top: 30px;
	display: flex;
	padding-left: 10px;
	padding-right: 10px;
	width: 300px;
}
.searchcontfind {
	font-size: 13px;
	width: 120px;
	border-radius: 0px;
	text-transform: none;
	font-weight: bold;
	margin-top: 32px;
	line-height: 1.2;
}
.applybutton {
	color: white;
	width: 140px;
	font-size: 13px;
	border-radius: 0px;
	text-transform: none;
	font-weight: bold;
	margin-right: 0px;
}
.pngdiv,
.docdiv,
.pdfdiv {
	text-align: center;
}
.cwodpop .modal-content,
.applicantappiliedwo .modal-content {
	background-color: #1e2f41;
	box-shadow: none;
}
.cwodpop,
.applicantappiliedwo {
	max-width: 1200px;
	width: 950px !important;
}
.cwodpop .modal-header,
.applicantappiliedwo .modal-header {
	border: none;
	padding: 0px !important;
}
.sbsd {
	margin: 15px 0px;
}
.start_dateeeeeee {
	background-color: #1e2f41;
	border: none;
	height: 35px;
	font-size: 12px;
	color: white;
	/* width: 215px; */
	width: 180px;
	padding-left: 10px;
	width: 100% !important;
}
.start_dateeeeeee input {
	/* color: white; */
	color: #6d757d;
}
.start_dateeeeeee .react-calendar {
	width: 240px;
	background-color: #1e2f41;
	/* color: white !important; */
	color: #6d757d !important;
}
.start_dateeeeeee .react-calendar__navigation button {
	min-width: 20px !important;
	/* color: white; */
	color: #6d757d;
}
.start_dateeeeeee
	.react-date-picker__calendar.react-date-picker__calendar--open {
	right: -50px !important;
}
.start_dateeeeeee
	button.react-calendar__tile.react-calendar__month-view__days__day.react-calendar__month-view__days__day--neighboringMonth {
	max-width: 35px !important;
	/* color: white; */
	color: #6d757d;
}
.start_dateeeeeee
	button.react-calendar__tile.react-calendar__month-view__days__day {
	color: #6d757d;
	/* color: white; */
}
.start_dateeeeeee .react-date-picker__wrapper {
	border: none;
	padding-left: 10px;
}
.start_dateeeeeee .react-calendar__navigation {
	margin-bottom: 0px !important;
}
.start_dateeeeeee
	.react-date-picker__calendar-button.react-date-picker__button {
	display: none;
}
@media (max-width: 1699px) and (min-width: 1500px) {
	.searchcontfind {
		margin-top: 24px !important;
	}
}

/*  */
/* Contractor profile */
.worktype_col {
	background-color: #415569;
}
.labeltools {
	font-weight: bold;
	padding: 10px 10px 10px 20px;
}
.profileedit_img {
	vertical-align: middle;
	width: 22px;
	height: 22px;
	border-radius: 50%;
	margin-right: 5px;
}
.profileconedit_but {
	border-radius: 0px;
	color: white;
	font-size: 13px;
	/* background-color: #29b6f6 !important; */
	padding: 0.375rem 6.5px 0.375rem 5px;
}
.toolbut {
	background-color: lightgrey !important;
	border: none !important;
}
.contprofiletools_div {
	border: 2px solid #34485a;
	padding: 15px;
}
.contractorprofilediv {
	background-color: #16222d !important;
	padding: 20px;
	color: white;
	margin-top: 50px;
	padding-left: 251px;
	/* padding-left: 226px; */
}
.dashdiv {
	padding-left: 10px;
	padding-right: 10px;
}
.editconprofile {
	border-radius: 0px;
	width: 150px;
	font-size: 13px;
	margin-right: 10px;
}
.typework_but {
	border-radius: 0px;
	/* width: 150px; */
	font-size: 13px;
}
.editprofilepop .modal-content {
	background-color: #16222d !important;
	box-shadow: none;
}
.editprofilepop .modal-header {
	border-bottom: none;
}
.editprofilepop .modal-header h5 {
	font-weight: bold;
}
.editprofilepop .modal-footer {
	border-top: none;
}
.editprofilepop {
	max-width: 1200px;
	width: 950px !important;
}
.editprofilepop label {
	font-size: 12px;
}
.editprofilepop input {
	background-color: #1e2f41;
	border: none;
	font-size: 12px;
	color: white;
}
.custom-file {
	background-color: #1e2f41;
	border: none;
}
.profilepic {
	width: 160px;
	height: 160px;
}
.profilecol1 {
	padding: 0px 40px;
}
.profilecol2 {
	padding: 0px 40px;
}
.profilecol3 {
	padding: 0px 40px 0px 40px;
}
.profilecol4 {
	padding: 0px 40px;
}
.gobut {
	/* border-radius: 0px;
    margin-top: 0px; */
	/* line-height: 1.2; */
	/* font-size: 12px; */
	background-color: #33b5e6;
	border: none;
	color: white;
}
.submitbut {
	border-radius: 0px;
	font-size: 12px;
}
.canbut {
	border-radius: 0px;
	color: white;
	font-size: 12px;
}
.canbut:hover {
	color: white;
}
.conprofilepicdiv {
	padding-bottom: 40px;
}
.editprofilepop .form-control:disabled,
.editprofilepop .form-control[readonly] {
	background-color: #1e2f41;
}
/* Responsive */
/* laptops with hipdi */
@media (min-width: 1200px) and (max-width: 1499px) {
}
/* laptops with touch & mdpi */
@media only screen and (min-width: 1030px) and (max-width: 1366px) {
}
/* sir laptop */
@media (min-width: 1500px) and (max-width: 1699px) {
}
/* big desktops */
@media (min-width: 1900px) {
}
/* big des */
@media (min-width: 1700px) and (max-width: 1899px) {
}
/* tab */
@media (min-width: 768px) and (max-width: 1023px) {
}
/* Phone */
@media (max-width: 766px) {
	.editprofilepop {
		width: 374px !important;
		margin: 0px;
	}
	.profilecol1 {
		padding: 0px 10px;
	}
	.profilecol2 {
		padding: 0px 10px;
	}
	.profilerowcont {
		display: block;
	}
}
/* Ipad pro */
@media (min-width: 992px) and (max-width: 1199px) {
}
/* Ipad */
@media (min-width: 767px) and (max-width: 991px) {
	.editprofilepop {
		width: 768px !important;
		margin: 0px;
		/* height: 700px; */
	}
	.profilepic {
		width: 100px !important;
		height: 100px !important;
	}
}
/* End Responsive */

/*  */
/* user management */
.usermagementdiv {
	background-color: #16222d !important;
	padding: 20px;
	color: white;
	margin-top: 50px;
	/* padding-left: 251px; */
	padding-left: 254px;
}
.selectac option {
	font-size: 12px !important;
}
.selectac {
	font-size: 12px !important;
	color: white;
}
.selectac:focus {
	border: none !important;
	box-shadow: none !important;
}
.adduser {
	border-radius: 0px;
	font-size: 13px;
}
.adduserpop .modal-content {
	background-color: #16222d !important;
	box-shadow: none;
}
.adduserpop .modal-header {
	border-bottom: none;
}
.adduserpop .modal-header h5 {
	font-weight: bold;
}
.adduserpop .modal-footer {
	border-top: none;
}
.adduserpop div {
	padding: 5px 10px;
}
/* .adduserpop{
    max-width: 1200px;
    width: 950px !important;
} */
.adduserpop label {
	font-size: 12px;
}
.adduserpop input {
	background-color: #1e2f41;
	border: none;
	border-radius: 0px;
	font-size: 12px;
	color: white;
}
.adduserpop .form-control:disabled,
.adduserpop .form-control[readonly] {
	background-color: #1e2f41;
}
.usertable td,
.usertable th,
.usertable tr {
	color: white;
	font-size: 12px;
	border-bottom: 2px solid #2c3f52 !important;
}
.usertable td,
.usertable th {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
}
.usertable thead tr:nth-of-type(odd) {
	background-color: #1e2f41 !important;
}
.usertable thead tr:nth-of-type(even) {
	background-color: #16222c !important;
}
.usertable tbody tr:nth-of-type(odd) {
	background-color: #16222c !important;
}
.usertable tbody tr:nth-of-type(even) {
	background-color: #1e2f41 !important;
}

/* Message css */
.messagemaindiv {
	background-color: #16222d !important;
	padding: 20px 20px 0px 20px;
	color: white;
	margin-top: 50px;
	/* padding-left: 251px; */
	padding-left: 260px;
}
.messagesearchdiv {
	background-color: #1e2f41 !important;
	border: none;
	padding: 5px 5px 5px 15px;
	font-size: 13px;
	color: white;
}
.inputmessagefield {
	background-color: #1e2f41 !important;
	border: none;
}
.textareamessage {
	background-color: #1e2f41 !important;
	border: none !important;
	font-size: 12.5px;
	color: white;
}
.searchchatlistcol {
	padding-right: 0px;
	padding-left: 0px;
	border-left: 2px solid #34485a;
}
.rechatdiv {
	border-bottom: 2px solid #34485a;
	padding-left: 10px;
}

.float-left{
	float: left;
}

.searchmdiv {
	/* border: 2px solid #34485a; */
	padding-left: 10px;
	padding-right: 10px;
}
.chatlist li {
	border-bottom: 2px solid #34485a;
	padding: 15px;
	cursor: pointer;
}
.activechat {
	background-color: #1e2f41 !important;
}
.detailchatdiv {
	border-left: 2px solid #34485a;
	padding-left: 15px;
	padding-right: 15px;
	/* border-right: 2px solid #34485a; */
}
.textareasenddiv {
	display: flex;
}
.textareasenddiv2 {
	padding-top: 25px;
}
.chatimg {
	/* vertical-align: middle; */
	width: 25px;
	height: 25px;
	border-radius: 50%;
	margin-right: 15px;
}
.outerdiv_messagesearch {
	margin-bottom: 15px;
}
.namedivmessage {
	border-bottom: 2px solid #34485a;
	padding: 10px;
	display: flex;
}
.messagepchat {
	/* height: 30px; */
	border-radius: 10px;
	background-color: #1e2f41 !important;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 8px 12px;
	margin-bottom: 10px;
	font-size: 12.5px;
}
.chatheadimg {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	margin-right: 15px;
}
.pchat {
	/* height: 490px; */
	height: 515px;
	overflow-y: scroll;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-left: 15px;
}
.pchat::-webkit-scrollbar {
	display: none;
}
.sendmessagebut {
	font-size: 12px;
	border-radius: 0px;
}
.message_stikebackgroundline {
	text-align: center;
	position: relative;
	z-index: 1;
}
.message_stikebackgroundline span {
	background: #16222d;
	padding: 0 8px;
	font-size: 14px;
}
.registeinput .input[text] {
	height: 60px !important;
	font-size: small;
}
.css-c91z-NoDataComponent {
	padding: 4px !important;
}
h5.message_stikebackgroundline::before {
	border-top: 1px solid #34485a;
	content: "";
	margin: 0 auto;
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	z-index: -1;
}

.range-field input[type="range"] + .thumb {
	background-color: #47c8ef00 !important;
}
span.thumb span:after {
	content: " miles";
}
/* auto suggestions */
.no-suggestions {
	color: #999;
	padding: 0.5rem;
}
.suggestions {
	border: 1px solid #999;
	border-top-width: 0;
	list-style: none;
	margin-top: 0;
	max-height: 143px;
	overflow-y: auto;
	padding-left: 0;
	width: 100%;
	text-align: left;
	font-size: small;
}
.suggestions li {
	padding: 0.5rem;
}
.suggestion-active,
.suggestions li:hover {
	cursor: pointer;
	font-weight: 700;
	text-align: left;
}
.suggestions li:not(:last-of-type) {
	border-bottom: 1px solid #999;
}
.upperhomediv {
	/* clip-path: polygon(0% 0%, 100% 0, 100% 50%, 50% 100%, 0 50%); */
	/*clip-path: polygon(0 0, 100% 1%, 100% 50%, 50% 70%, 0% 50%);*/
	/* height: 700px; */
	/*height: 850px;*/
	background-image: linear-gradient(#1a1f25, #ff000000),
		url(https://images.pexels.com/photos/323705/pexels-photo-323705.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=1200);
	/* background-size: cover;
    background-repeat: no-repeat;
    background-color: #007bff;*/
}
/*//menu items*/
ul.navbar-nav.ml-auto li {
	/*padding-right: 5%;*/
	padding-right: 32px;
	font-size: 12px !important;
}
.carousel-multi-item .carousel-inner {
	padding-top: 4.5rem;
	padding-bottom: 1rem;
}
.caroudiv h4 {
	font-size: 19px;
	margin-top: 21px;
}
.caroudiv p {
	font-size: 12px;
	margin-top: 1%;
	margin-top: 1.5%;
	margin-bottom: 0%;
}
.selcdiv select,
.selrdiv select,
.selwtdiv select {
	background-color: white !important;
	padding-top: 12px;
	font-size: 12px;
	padding-bottom: 0;
}
body {
	/*background-color: #ffffff !important;*/
}
.inputfieldhome input {
	/*    width: 200px;
    padding: 10px;*/
	width: 100%;
	height: 100%;
	font-size: 12px;
}
ul.list-group.foot_list div {
	float: left;
	margin-right: 9px;
}
.container.text-right.iconsocial {
	color: #cccccced;
	/*margin-right: 15px;*/
}
ul.list-group.foot_list h6 {
	margin-left: 20px;
	/* margin-bottom: -22px; */
	margin-top: -16px;
	font-size: 12px;
}
ul.list-group.foot_list p {
	margin-top: -5px;
	color: #cccccced;
}
.col-md-5.title_div p {
	color: #cccccced;
}
.container.text-right.iconsocial i {
	color: #cccccced;
	margin-left: 13px;
}
.input-group.footinput input {
	font-size: 12px;
}
.footer-copyright.py-3 {
	color: #cccccced;
	padding-top: 9px;
}
.side-nav.wide.sidenavsw9.side-slide-enter-done ul {
	width: 100%;
}
.side-nav.wide.sidenavsw9 ul {
	width: 100%;
}
input#timePicker {
	color: white;
	font-size: 12px;
	margin-top: -34px;
}

/*// range*/
div .range-field input[type="range"] + .thumb {
	position: static;
}
div .range-field input[type="range"] + .thumb .value,
div .range-field input[type="range"] + .thumb.active .value {
	margin-top: -61px;
	transform: none !important;
}
input#timePickerEditCheckIn,
input#timePickerEditCheckOut {
	color: white;
	font-size: 12px;
	margin-top: -34px;
}
.selectdrop {
	align-items: flex-start !important;
}
.colpngdiv {
	width: 20px !important;
}
.viewfile_div {
	display: inline-block !important;
	width: 895px !important;
}
.divworkdetail {
	margin-left: 10px !important;
	width: 502px !important;
}
.registercol4 {
	width: 262px !important;
}
.registercol5 {
	width: 260px !important;
}
.emailin {
	width: 487px;
}
a .nav-link .Ripple-parent {
	background-color: #4285f487 !important;
}
.iconsocial {
	text-align: left !important;
}
.list-unstyled {
	display: inline-block;
}
.brown.darken-1 {
	background-color: #363636 !important;
	position: relative !important;
}
.arrowgo {
	background-color: #eac923;
}
.footinput {
	width: 90%;
}
.carousel-multi-item .controls-top .btn-floating {
	border-radius: 0% !important;
	background: #35ce3a;
}
.iconft {
	color: #eac923 !important;
}
.navitems {
	width: 118px;
}
.navitem {
	width: 90px;
	padding-right: 4% !important;
}
.divworkdetail {
	width: 815px !important;
}
.hrline2 {
	height: 1px !important;
}
.timepicker {
	background-color: #182837 !important;
	border-bottom: none !important;
	margin-top: -25px !important;
	/*width: 172px;*/
	padding-left: 10px !important;
	width: -webkit-fill-available;
}
.uploadcheck {
	background-color: #182837 !important;
	width: 184px;
}
.paddingresponsive {
	margin-bottom: -20px;
}
.usertable_row {
	margin-left: 30px !important;
}
.ccwo_table {
	/* display: inline;*/
	/* padding-bottom: 40px;*/
}
.showresend {
	margin-left: 500px;
}
.upload_deliverables {
	background-color: #182837 !important;
	border: none;
	color: white;
	font-size: 11px;
	content: "skdklsjd";
	position: relative;
	/*height: 14px;*/
	height: calc(1.5em + 0.75rem + 2px);
	width: 172px;
	cursor: pointer;
	z-index: 0;
	color: gray;
}
.upload_deliverables input {
	opacity: 0;
	position: absolute;
	z-index: 1;
	color: gray;
}
.upload_deliverables:after {
	content: "Upload File";
	padding: 7px;
	position: absolute;
	/*margin-top: 10px;*/
	color: gray;
}
.form-group.startoftime,
.form-group.endoftime {
	padding-top: 26px;
}
.wo_app_b {
	height: 243px;
}
.wo_app_a {
	height: 143px;
}
input.form-control.amountwo_div,
input.form-control.delive_div,
input.form-control.dark-input,
input.form-control.work_div,
input.form-control.comm_div,
input.form-control.qaty_div,
input.form-control.estpay_div,
input.form-control.after_div,
input.form-control.small-styled-input,
input.form-control.small-styled-input-half,
select.browser-default.custom-select.per {
	background-color: #182837 !important;
	border: none;
	border-radius: 0;
	color: white;
	font-size: 11px;
}
input#approved_variable_after {
	width: 114%;
}
.row.row_app_a {
	margin-bottom: 14px;
}
.recentlyused_modal {
	text-align: left;
}
.css-6wsm8k-DropdownHandleComponent {
	margin: 10px 0 0 5px !important;
}
.navbar.scrolling-navbar.top-nav-collapse {
	background-color: #182837;
}
input.form-control.amountwo_div:focus,
input.form-control.delive_div:focus,
input.form-control.dark-input:focus,
input.form-control.work_div:focus,
input.form-control.comm_div:focus,
input.form-control.qaty_div:focus,
input.form-control.estpay_div:focus,
input.form-control.small-styled-input:focus,
input.form-control.small-styled-input-half:focus,
input.form-control.after_div:focus{
	background-color: white !important;
}
table.table.table-borderless.client_table
	tbody
	tr
	td:nth-child(2)
	div:nth-child(2) {
	font-size: 12px !important;
	margin-top: 4px;
}
footer.page-footer .footer-copyright {
	margin-bottom: -9px;
}
.container-fluid.footer-copyright.py-3 hr {
	visibility: hidden;
	display: none;
}
.selectdropstyle div div {
	color: white;
}
.selectdropstyle .css-yk16xz-control:focus {
	background-color: white !important;
	color: black !important;
}
.selectdropstyle .css-yk16xz-control:focus div div {
	color: black;
}

/* .css-11aao21-allyText{
  color:black !important;

  }
*/
.selectdropstyle .css-yk16xz-control {
	background-color: #1e2f41 !important;
	border: none !important;
	font-size: 12px;
	background-color: #1e2f41;
	border: none;
	border-radius: 0px;
	font-size: 12px;
	color: white;
}
.css-1okebmr-indicatorSeparator {
	background-color: transparent !important;
}
.selectdropstyle span.css-11aao21-a11yText {
	color: black !important;
	/*background-color: black !important;*/
}
div.css-2613qy-menu li {
	color: black !important;
	background-color: black !important;
}
.css-1pahdxg-control div.css-g1d714-ValueContainer div.css-1uccc91-singleValue {
	color: black !important;
}
.css-1pahdxg-control
	div.css-1hb7zxy-IndicatorsContainer
	div.css-tlfecz-indicatorContainer {
	color: black !important;
}
.adcustrow {
	flex-basis: 100%;
	height: 0;
	padding: 20px;
	margin-bottom: 36px;
	margin-left: 0 !important;
	height: auto;
	font-size: 12px;
	/*/ overflow-y: scroll; /*/
}
.ccwo_table td,
.ccwo_table th {
	font-size: 12px;
}
.adcustrow button {
	background-color: #4abde8;
}
.find_apply10 {
	width: inherit;
}
.cursor_pointer {
	cursor: pointer;
}
.clientinner_table td:last-child {
	width: 264px;
}
.woddiv {
	width: -webkit-max-content;
	width: max-content;
}

.srchimg {
	height: 28px;
	margin-right: 10px;
}
.client_table b {
	line-height: 1.5;
}
.modal-full-height.modal-right.modal-dialog.wodpop b {
	line-height: 1.5;
}
.srtend_div {
	margin-right: 25px;
}
.app_div {
	display: contents;
}

.links_table td,
.vendor_table td,
.location_table td,
.skills_table td {
	color: grey;
}
.clrtable {
	color: #b1b1b1 !important;
}

.navbar {
	box-shadow: unset;
}

a.navbar-brand.active {
	padding-left: 5em;
}

.selcdiv select,
.selrdiv select,
.selwtdiv select {
	background-color: white !important;
	padding-top: 12px;
	font-size: 12px;
	padding-bottom: 0;
	height: 42px;
	width: 213px;
	padding: 0;
	padding-left: 10px;
	margin: 0;
	color: gray;
}
.selcdiv,
.selrdiv,
.selwtdiv {
	background-color: white !important;
	border: 1px solid #a9a9a9 !important;
	width: 100%;
	padding: 0;
	margin: 0;
}

.custom-select:focus {
	border-color: unset;
	outline: 0;
	box-shadow: unset;
}

a.navbar-brand.active span {
	font-weight: bolder;
}

p.homeimg_text {
	font-weight: bold;
	font-size: 14px;
}

.text_div2 {
	font-size: 14px;
}

.btn-floating i {
	font-size: 11px;
	margin-top: -8px;
	line-height: 47px;
}

.carousel-multi-item .controls-top .btn-floating {
	border-radius: 0% !important;
	background: #35ce3a;
	height: 30px;
	width: 30px;
}

.secondhomecor .controls-top {
	margin-top: 49px;
}

section.text-center.my-5 {
	margin: 0 !important;
}

.carousel-item.active.carousel-slide-item p {
	font-size: 14px;
}

.text_div3 {
	font-size: 14px;
}

p.text_div3 {
	width: 22vw;
	font-size: 12px;
}

.skills_table tbody tr td:nth-child(1),
.location_table tbody tr td:nth-child(1),
.vendor_table tbody tr td:nth-child(1),
.links_table tbody tr td:nth-child(1) {
	font-size: 12px;
}

.skills_table tbody tr td:nth-child(2),
.location_table tbody tr td:nth-child(2),
.vendor_table tbody tr td:nth-child(2),
.links_table tbody tr td:nth-child(2) {
	font-size: 11px;
	color: gray;
}

.container-fluid.text-left.icongroupres {
	padding: 0 !important;
	font-size: 12px;
	max-width: 1087px;
}
footer .container-fluid.text-center.text-md-left {
	max-width: 1140px;
	font-size: 12px;
	padding-left: 15px !important;
}
p.homeimg_text {
	font-weight: bold;
	font-size: 14px;
	/*margin-top: 14px;*/
}
.homeimg_div img {
	margin-top: 9px;
}
.text_div1 {
	font-size: 14px;
}
.input-group.footinput div.input-group-prepend span {
	border-radius: 0 2px 2px 0;
}
h6.title.resfootertitle {
	font-size: 14px;
}

.col-md-5.title_div p {
	font-size: 12px !important;
}
p.homeimg_text1 {
	font-size: 11px;
	margin-top: -14px;
	font-weight: bold;
	color: #808080c4;
}

footer.container-fluid.text-center.text-md-left {
	padding-left: 15px;
}

select.browser-default.custom-select.selectac {
	background-color: rgb(24, 40, 55);
	width: 240px;
	margin-bottom: 23px;
}
.signindiv {
	z-index: 99999;
	overflow: auto;
	/* position: absolute;*/
}
.upperhomediv {
	z-index: 99999;
}
.home_page {
	z-index: 99999;
	transition: opacity 0.15s linear;
}

.wo_table {
	font-size: 12px;
}
.wo_table td {
	font-size: 12px;
	padding-top: 8px !important;
	padding-bottom: 8px !important;
}
.wo-balance{
	display: flex;
	justify-content: center;
}



.logo {
  width: 50px;
  height: 50px;
}
